<template>
  <div id="surveyVizPanel"></div>
</template>
<script setup>
import { onMounted } from 'vue';
import 'survey-analytics/survey.analytics.min.css';
import { Model, slk } from 'survey-core';
import { VisualizationPanel } from 'survey-analytics';

const props = defineProps({
  surveySchema: {
    type: Object,
    required: true,
  },
  surveyResults: {
    type: Object,
    required: true,
  },
  lk: {
    type: String,
    required: false,
    default: null,
  },
});

onMounted(() => {
  if (props.lk) {
    slk(props.lk);
  }
  const survey = new Model(props.surveySchema.content);
  const vizPanelOptions = {
    allowShowPercentages: true,
    allowTopNAnswers: true,
    survey,
  };
  const vizPanel = new VisualizationPanel(
    survey.getAllQuestions(),
    props.surveyResults.map(sr => {
      return sr.content;
    }),
    vizPanelOptions,
  );
  vizPanel.render('surveyVizPanel');
});
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap&subset=latin-ext');

.sa-question__content {
  background-color: white !important;
  border-radius: 12px;
  /* background-color: rgb(248, 249, 253) !important; */
  filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
}

.sa-question__title {
  color: rgba(70, 145, 247, 1) !important;
  font-family: 'Poppins', 'ui-sans-serif', 'system-ui', '-apple-system', 'Segoe UI', 'Roboto', 'Ubuntu', 'Cantarell', 'Noto Sans', 'sans-serif';
  font-weight: 500;
}

.sa-toolbar__button {
  border-radius: 100px;
  font-family: 'Poppins', 'ui-sans-serif', 'system-ui', '-apple-system', 'Segoe UI', 'Roboto', 'Ubuntu', 'Cantarell', 'Noto Sans', 'sans-serif';
  color: rgb(139, 146, 169);
  font-size: 14px;
  font-weight: 400;
  border-width: 1px;
  line-height: 1rem;
  border-color: transparent;
  display: flex !important;
  align-items: center;
}
.sa-toolbar__button:hover {
  color: rgba(70, 145, 247, 1);
  border-color: rgba(70, 145, 247, 1);
}

.sa-question__select-wrapper .sa-question__select {
  margin-left: 1.5rem;
  display: block;
  height: 2.5rem;
  width: 100%;
  border-radius: 100px;
  border-width: 1px;
  border-color: #d1d5db;
  background-color: white;
  padding-left: 1rem;
  padding-right: 1.5rem;
  margin-left: 12px;
  font-size: 14px;
  line-height: 1rem;
  font-weight: 400;
  color: rgb(139, 146, 169);
  font-family: 'Poppins', 'ui-sans-serif', 'system-ui', '-apple-system', 'Segoe UI', 'Roboto', 'Ubuntu', 'Cantarell', 'Noto Sans', 'sans-serif';
}

.sa-question__select:hover {
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
}

.sa-visualizer__content > div > p {
  display: flex;
  justify-content: center;
}
</style>
