<template>
  <div class="flex h-full flex-col justify-between">
    <div>
      <h2>{{ $t('import_patients.new_patient_form.title') }}</h2>
      <form class="mt-12 text-left" @submit.prevent>
        <div
          v-if="showError"
          class="mb-5 flex w-full items-center justify-between border-l-2 border-danger bg-red-100 p-4 text-sm text-danger"
          @click="handleCloseAlert"
        >
          <span class="mr-2 text-red-dark">{{ errorMessage }}</span>
          <hero-icon name="CloseHeroIcon" classes="hover:cursor-pointer" size="small"></hero-icon>
        </div>
        <div
          v-if="showSuccess"
          class="mb-5 flex w-full items-center justify-between border-l-2 border-green bg-green-100 p-4 text-sm text-success"
          @click="showSuccess = false"
        >
          <span class="mr-2 text-green-dark">{{ successMessage }}</span>
          <hero-icon name="CloseHeroIcon" classes="text-sm hover:cursor-pointer" size="small"></hero-icon>
        </div>
        <div class="mb-6 grid gap-6 md:grid-cols-2">
          <div v-if="store.sendEsatisEmailing === 'true' && store.sendingOption === 'email'">
            <label for="exitDate" class="mb-2 block text-sm font-medium text-textSecondary">{{
              $t('import_patients.new_patient_form.exit_date')
            }}</label>
            <VueTailwindDatepicker
              id="exitDate"
              v-model="v$.exitDate.$model"
              i18n="fr"
              as-single
              :formatter="formatter"
              :shortcuts="false"
              :options="options"
              :auto-apply="true"
              placeholder="Sélectionnez..."
              input-classes="block h-10 rounded-lg border-gray-200 bg-white text-xs font-medium text-gray-500 hover:drop-shadow-md"
            />
            <p v-if="v$.exitDate.$error" class="text-xs text-danger">{{ $t('import_patients.new_patient_form.set_exit_date_error') }}</p>
          </div>
          <fieldset :class="store.sendEsatisEmailing === 'true' ? 'col-span-1' : 'col-span-2'">
            <div class="flex flex-col items-start gap-4">
              <div class="block text-sm font-medium text-textSecondary">{{ $t('import_patients.new_patient_form.civility') }}</div>
              <div class="flex items-center gap-4">
                <div v-for="(civility, index) in civilities" :key="index" class="flex items-center">
                  <input
                    :id="`civility_${index}`"
                    v-model="selectedCivility"
                    type="radio"
                    name="redirection"
                    :value="civility"
                    class="form-radio h-4 w-4 border-gray-200 hover:drop-shadow-md focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                  />
                  <label :for="`civility_${index}`" class="ms-2 text-sm font-medium text-textPrimary">
                    <span>
                      {{ civility }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </fieldset>
          <div>
            <label for="firstName" class="mb-2 block text-sm font-medium text-textSecondary">{{
              $t('import_patients.new_patient_form.first_name')
            }}</label>
            <input
              id="firstName"
              v-model="v$.firstName.$model"
              type="text"
              class="focus:shadow-focus form-input h-10 w-full overflow-hidden rounded-lg border-gray-200 bg-white px-4 text-xs font-medium text-textPrimary placeholder:text-textSecondary hover:drop-shadow-md"
              required
            />
            <p v-if="v$.firstName.$error" class="text-xs text-danger">{{ $t('import_patients.new_patient_form.two_letters_min') }}</p>
          </div>
          <div>
            <label for="lastName" class="mb-2 block text-sm font-medium text-textSecondary">{{ $t('import_patients.new_patient_form.name') }}</label>
            <input
              id="lastName"
              v-model="v$.lastName.$model"
              type="text"
              class="focus:shadow-focus form-input h-10 w-full overflow-hidden rounded-lg border-gray-200 bg-white px-4 text-xs font-medium text-textPrimary placeholder:text-textSecondary hover:drop-shadow-md"
              required
            />
            <p v-if="v$.lastName.$error" class="text-xs text-danger">{{ $t('import_patients.new_patient_form.two_letters_min') }}</p>
          </div>
          <div class="flex flex-col">
            <label for="phone" class="mb-2 text-sm font-medium text-textSecondary"
              >{{ $t('import_patients.new_patient_form.phone_number') }} <span v-show="store && store.sendingOption === 'sms'">*</span></label
            >
            <vue-tel-input
              v-model="phoneNumber"
              :input-options="vueTelInputOptions"
              style-classes="z-20 focus:shadow-focus form-input h-10 w-full rounded-lg border-gray-200 bg-white px-4 text-xs font-medium text-textPrimary hover:drop-shadow-md"
              @validate="event => validatePhone(event)"
            ></vue-tel-input>
            <p v-if="validPhone === false" class="text-xs text-danger">{{ $t('patients.new.phone_number.invalid_message') }}</p>
          </div>
          <div>
            <label for="patientEmail" class="mb-2 block text-sm font-medium text-textSecondary"
              >{{ $t('import_patients.new_patient_form.email') }} <span v-show="store && store.sendingOption === 'email'">*</span></label
            >
            <input
              id="patientEmail"
              v-model="v$.patientEmail.$model"
              type="email"
              class="focus:shadow-focus form-input h-10 w-full overflow-hidden rounded-lg border-gray-200 bg-white px-4 text-xs font-medium text-textPrimary placeholder:text-textSecondary hover:drop-shadow-md"
              required
            />
            <p v-if="v$.patientEmail.$error" class="text-xs text-danger">{{ $t('import_patients.new_patient_form.email_format_error') }}</p>
          </div>
          <div v-if="displayTagSelect">
            <label for="tagSelect" class="mb-2 block text-sm font-medium text-textSecondary">{{ $t('import_patients.new_patient_form.tag') }}</label>
            <select
              id="tagSelect"
              v-model="tagId"
              name="tagId"
              class="focus:shadow-focus form-select inline-flex h-10 w-full shrink overflow-hidden rounded-lg border-gray-200 bg-white px-4 text-xs font-medium text-gray-500 hover:drop-shadow-md"
            >
              <option value="" selected>{{ $t('import_patients.new_patient_form.select_tag') }}</option>
              <option v-for="(tag, index) in tags" :key="'tag_' + index" :value="tag.id">{{ tag.name }}</option>
            </select>
          </div>
          <!--div v-else>
            <label for="tagName" class="block mb-2 text-sm font-medium text-textSecondary">Tag</label>
            <input id="tagName" v-model="tagName" placeholder="ex: médecin, catégorie, service..." class="w-full h-10 px-4 overflow-hidden text-xs font-medium bg-white border-gray-200 rounded-lg focus:shadow-focus form-input text-textPrimary placeholder:text-textSecondary hover:drop-shadow-md" required>
          </div-->
        </div>
      </form>
    </div>
    <div class="mt-24 flex items-center justify-center">
      <a
        class="mr-2 flex w-32 items-center justify-center rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-textSecondary hover:border hover:border-primary hover:text-primary hover:no-underline"
        @click="$emit('previous-step')"
      >
        <div class="flex h-full items-center">
          <hero-icon name="ChevronLeftIonIcon" size="small"></hero-icon>
        </div>
        {{ $t('buttons.previous') }}
      </a>
      <button
        class="group flex w-32 items-center justify-center rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
        :class="{ 'cursor-not-allowed opacity-75': disableSubmit }"
        :disabled="disableSubmit || loading"
        @click="handleSubmit"
      >
        <TurningLoader v-if="loading" size="sm" class="mr-2" />
        <span v-else class="text-sm font-medium text-white group-disabled:text-textLight">{{ $t('buttons.create') }}</span>
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';
import { create_or_update_patient } from '@services/importService.js';
import { useImportPatientsStore } from '@stores/importPatients.js';

const store = useImportPatientsStore();

const { t } = useI18n();

const props = defineProps({
  clinics: {
    type: Array,
    required: true,
  },
  beforePath: {
    type: String,
    required: true,
  },
  sollicitationType: {
    type: String, // manual, file_one_establishment, file_multiple_establishments
    required: true,
  },
  currentClinic: {
    type: Object,
    required: false,
    default: () => {
      return {};
    },
  },
  tags: {
    type: Array, // array of tag instances
    required: false,
    default: () => {
      return [];
    },
  },
  civilities: {
    type: Array,
    required: false,
    default: () => {
      return [];
    },
  },
});

defineEmits(['close', 'previous-step']);

const selectedCivility = ref(null);
const firstName = ref(null);
const lastName = ref(null);
const patientEmail = ref(null);
const phoneNumber = ref(null);
const errorMessage = ref('');
const showError = ref(false);
const tagId = ref(null);
const vueTelInputOptions = ref({
  styleClasses:
    'bg-transparent rounded-r-lg text-textPrimary placeholder-gray-400 focus:rounded-r-lg target:rounded-r-lg visited:rounded-r-lg hover:rounded-r-lg active:rounded-r-lg autofill:rounded-r-lg',
  name: 'patient[phone_number]',
  id: 'phone',
  autocomplete: 'on',
});
const validPhone = ref(undefined);
const successMessage = ref('');
const showSuccess = ref(false);
const loading = ref(false);
const exitDate = ref('');
const formatter = ref({ date: 'DD/MM/YYYY', month: 'MMM' });
const options = ref({
  shortcuts: {
    today: "Aujourd'hui",
    yesterday: 'Hier',
    past: period => `${period} derniers jours`,
    currentMonth: 'Mois en cours',
    pastMonth: 'Mois précédent',
  },
  footer: {
    apply: 'Appliquer',
    cancel: 'Annuler',
  },
});

onMounted(() => {
  initErrorMessage();
  resetCivility();
});

const validatePhone = event => {
  validPhone.value = event.valid;
};

const rules = computed(() => {
  if (store.sendEsatisEmailing === 'true' && store.sendingOption === 'email') {
    return {
      exitDate: {
        required,
      },
      firstName: {
        minLength: minLength(2),
      },
      lastName: {
        minLength: minLength(2),
      },
      patientEmail: { email },
    };
  } else {
    return {
      exitDate: {},
      firstName: {
        minLength: minLength(2),
      },
      lastName: {
        minLength: minLength(2),
      },
      patientEmail: { email },
    };
  }
});

const v$ = useVuelidate(rules, { patientEmail, firstName, lastName, exitDate });

const initErrorMessage = () => {
  errorMessage.value = t('users.standard_user_adding_message');
};

const resetCivility = () => {
  if (props.civilities.length > 0) {
    selectedCivility.value = props.civilities[0];
  }
};

const displayTagSelect = computed(() => {
  return props.tags.length > 0;
});

const handleCloseAlert = () => {
  showError.value = false;
  initErrorMessage();
};

const disableSubmit = computed(() => {
  return (
    v$.value.$invalid ||
    (store.sendingOption === 'email' && (patientEmail.value === null || patientEmail.value === '')) ||
    (store.sendingOption === 'sms' && (phoneNumber.value === null || phoneNumber.value === '')) ||
    (store.sendEsatisEmailing === 'true' && (exitDate.value === null || exitDate.value === ''))
  );
});

const handleSubmit = async () => {
  storeSettings();
  try {
    loading.value = true;
    const response = await create_or_update_patient(store.getPatientParams);
    successMessage.value = response.data.message;
    showSuccess.value = true;
    setTimeout(function () {
      showSuccess.value = false;
      loading.value = false;
      handleClose();
    }, 2000);
  } catch (error) {
    errorMessage.value = error.message || t('errors.default_message');
    showError.value = true;
    setTimeout(function () {
      showError.value = false;
      errorMessage.value = null;
      loading.value = false;
    }, 3000);
  }
};

const storeSettings = () => {
  store.updatePatient({
    patient: {
      prefix: selectedCivility.value,
      firstName: firstName.value,
      lastName: lastName.value,
      email: patientEmail.value,
      phoneNumber: phoneNumber.value,
      tagId: tagId.value,
      exitDate: store.sendEsatisEmailing === 'true' && store.sendingOption === 'email' ? exitDate.value : '',
    },
  });
};

const handleClose = () => {
  store.$reset();
  window.location.href = props.beforePath;
};
</script>
<style scoped>
:root {
  --vs-border-radius: 8px;
  --vs-border-color: #e5e7eb;
  --vs-selected-bg: #e1effe;
  --vs-selected-border-color: #e1effe;
  --vs-selected-color: #4691f7;
  --vs-controls-color: #4691f7;
}
.vue-tel-input {
  border-radius: 0.5rem !important;
  display: flex;
  border: 1px solid #e5e7eb !important;
  text-align: left;
}
</style>
