<template>
  <div id="surveyDataTable" class="w-full, overflow-x-auto"></div>
</template>

<script setup>
import { onMounted } from 'vue';
import 'tabulator-tables/dist/css/tabulator.min.css';
import 'survey-analytics/survey.analytics.tabulator.min.css';
import { Model, slk } from 'survey-core';
import { Tabulator } from 'survey-analytics/survey.analytics.tabulator';

const props = defineProps({
  surveySchema: {
    type: Object,
    required: true,
  },
  surveyResults: {
    type: Object,
    required: true,
  },
  lk: {
    type: String,
    required: false,
    default: null,
  },
});

onMounted(() => {
  if (props.lk) {
    slk(props.lk);
  }
  const survey = new Model(props.surveySchema);
  const surveyDataTable = new Tabulator(survey, props.surveyResults);
  surveyDataTable.render('surveyDataTable');
});
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap&subset=latin-ext');

.sa-tabulator__header {
  background-color: #4691f7 !important;
  border-radius: 12px 12px 0px 0px;
  /* background-color: rgb(248, 249, 253) !important; */
  filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
  color: white !important;
}

.sa-tabulator__header > select,
.sa-tabulator__header > input,
.sa-table__entries > select {
  margin-left: 1.5rem;
  height: 2.5rem;
  border-radius: 100px;
  border-width: 1px;
  border-color: #d1d5db;
  background-color: white;
  padding-left: 1rem;
  padding-right: 1.5rem;
  margin-left: 12px;
  font-size: 14px;
  line-height: 1rem;
  font-weight: 400;
  color: rgb(139, 146, 169);
  font-family: 'Poppins', 'ui-sans-serif', 'system-ui', '-apple-system', 'Segoe UI', 'Roboto', 'Ubuntu', 'Cantarell', 'Noto Sans', 'sans-serif';
}

.sa-table__entries {
  display: flex;
  align-items: center;
}

.sa-tabulator__downloads-bar > .sa-table__btn--gray {
  border-radius: 100px;
  border: 1px;
  background-color: white;
  color: rgb(139, 146, 169);
  font-weight: 400;
  font-family: 'Poppins', 'ui-sans-serif', 'system-ui', '-apple-system', 'Segoe UI', 'Roboto', 'Ubuntu', 'Cantarell', 'Noto Sans', 'sans-serif';
}

.sa-tabulator__downloads-bar > .sa-table__btn--gray:hover {
  background-color: #73c521;
  color: #3d7219;
  filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
}

.sa-table__global-filter {
  border-color: #d1d5db;
  background-color: white !important;
  font-weight: 400;
  color: rgb(139, 146, 169) !important;
  font-family: 'Poppins', 'ui-sans-serif', 'system-ui', '-apple-system', 'Segoe UI', 'Roboto', 'Ubuntu', 'Cantarell', 'Noto Sans', 'sans-serif';
}
input::placeholder {
  color: rgb(139, 146, 169);
}

.sa-table__entries-label {
  color: white !important;
  font-weight: 500;
  font-family: 'Poppins', 'ui-sans-serif', 'system-ui', '-apple-system', 'Segoe UI', 'Roboto', 'Ubuntu', 'Cantarell', 'Noto Sans', 'sans-serif';
}

.sa-tabulator__pagination-container {
  margin-left: 10px;
  background-color: white;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
