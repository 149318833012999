<template>
  <div>
    <div class="relative">
      <button
        :class="buttonClasses"
        :data-tooltip-target="`tooltip-publish-${surveySchemaId}`"
        data-tooltip-placement="bottom"
        @click="showPublishModal = true"
      >
        <hero-icon :name="buttonIconName" size="default"></hero-icon>
      </button>
      <default-tooltip
        :id="`tooltip-publish-${surveySchemaId}`"
        classes="bg-blue-lighter text-blue-500"
        :message="$t('survey_schemas.publish.modal.publish_tooltip')"
      />
    </div>
    <Modal v-model="showPublishModal" :title="$t('survey_schemas.publish.modal.title')" wrapper-class="modal-wrapper" modal-class="modal modal-lg">
      <div v-show="isError" class="mb-4 flex items-center justify-between rounded-lg bg-red-50 p-4 text-sm text-danger" role="alert">
        <hero-icon name="AlertOutlinetIonIcon" size="large" classes="pt-1 mr-2"></hero-icon>
        <p class="text-danger">{{ errorMessage }}</p>
      </div>
      <div v-show="isSuccessMessage" class="mb-4 flex items-center justify-between rounded-lg bg-green-50 p-4 text-sm text-green-dark" role="alert">
        <hero-icon name="CheckCircleOutlineHeroIcon" size="large" classes="pt-1 mr-2"></hero-icon>
        <p class="text-green-dark">{{ successMessage }}</p>
      </div>
      <div>
        <div class="form-group mb-8">
          <label for="clinics-select" class="mb-2 block text-sm font-medium text-textSecondary">{{
            $t('survey_schemas.publish.modal.select_clinic')
          }}</label>
          <div class="mb-6 flex items-start justify-start">
            <hero-icon name="AlertOutlinetIonIcon" size="large" classes="text-danger mr-2"></hero-icon>
            <span class="text-xs text-textSecondary">{{ $t('survey_schemas.publish.modal.alert') }}</span>
          </div>
          <div class="flex h-10 w-full items-center justify-start space-x-3 text-sm font-medium">
            <v-select
              id="clinics-select"
              v-model.trim="$v.selectedClinicId.$model"
              autocomplete
              :close-on-select="true"
              :deselect-from-dropdown="true"
              :options="clinics"
              :reduce="getClinicId"
              label="name"
              class="w-96 text-textPrimary"
              clearable
              :placeholder="$t('survey_schemas.publish.modal.select')"
            />
            <div v-if="$v.selectedClinicId.required.$invalid" class="flex items-center">
              <p class="ms-2 text-xs leading-normal text-danger">{{ $t('survey_schemas.publish.modal.required') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-10 flex items-center justify-end">
        <button
          class="rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-textSecondary hover:text-primary"
          @click="showPublishModal = false"
        >
          {{ $t('buttons.cancel') }}
        </button>
        <button
          class="rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
          :disabled="$v.$invalid || isLoading"
          @click="handlePublish"
        >
          <div v-if="isLoading" class="text-center">
            <TurningLoader text-color="text-textLight" size="sm" />
          </div>
          <div v-else>
            {{ $t('survey_schemas.publish.modal.submit_button') }}
          </div>
        </button>
      </div>
    </Modal>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { publishSurveySchema } from '@services/surveyService';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const { t } = useI18n();

const props = defineProps({
  clinics: {
    type: Array,
    required: true,
  },
  currentClinicId: {
    type: Number,
    required: true,
  },
  surveySchemaId: {
    type: Number,
    required: true,
  },
  buttonClasses: {
    type: String,
    default: 'text-gray-400 hover:text-primary flex justify-center',
    required: false,
  },
  buttonIconName: {
    type: String,
    default: 'EyeOutlineHeroIcon',
    required: false,
  },
});

const showPublishModal = ref(false);
const isLoading = ref(false);
const isError = ref(false);
const isSuccessMessage = ref(false);
const errorMessage = ref('');
const successMessage = ref('');
const selectedClinicId = ref(null);

const rules = computed(() => {
  return {
    selectedClinicId: {
      required,
    },
  };
});

const $v = useVuelidate(rules, { selectedClinicId });

const getClinicId = clinic => clinic['id'];

const handlePublish = async () => {
  try {
    isLoading.value = true;
    const result = await publishSurveySchema({
      surveySchemaId: props.surveySchemaId,
      selectedClinicId: selectedClinicId.value,
      currentClinicId: props.currentClinicId,
    });
    if (result?.ok) {
      successMessage.value = result.response.data.message;
      isSuccessMessage.value = true;
      setTimeout(() => {
        isSuccessMessage.value = false;
        successMessage.value = '';
        isLoading.value = false;
        showPublishModal.value = false;
        const path = `/clinics/${props.currentClinicId}/survey_schemas`;
        window.location.href = path;
      }, 1500);
    } else {
      errorMessage.value =
        result?.response?.status === 404 ? t('survey_schemas.publish.modal.unfound_page') : result?.error || t('survey_schemas.publish.modal.error');
      isLoading.value = false;
      isError.value = true;
      setTimeout(() => {
        isError.value = false;
        errorMessage.value = '';
      }, 3000);
    }
  } catch (error) {
    errorMessage.value = `Une erreur est survenue. Veuillez essayer à nouveau. (${error.message})`;
    isError.value = true;
    isLoading.value = false;
    setTimeout(() => {
      isError.value = false;
      errorMessage.value = '';
    }, 3000);
  }
};
</script>
<style setup>
:root {
  --vs-border-radius: 8px;
  --vs-border-color: #d1d5db;
  --vs-selected-bg: #e1effe;
  --vs-selected-border-color: #e1effe;
  --vs-selected-color: #4691f7;
  --vs-selected-padding: 3px;
  --vs-controls-color: #4691f7;
  --vs-font-size: 14px;
  --vs-dropdown-z-index: 1000;
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 120px;
  --vs-dropdown-box-shadow: 0px 3px 6px 0px var(--vs-colors--darkest);
}
</style>
