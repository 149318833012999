<template>
  <SurveyComponent :model="survey" />
</template>

<script setup>
import 'survey-core/defaultV2.min.css';
import { Model, slk } from 'survey-core';
import { ref, onMounted } from 'vue';
import { saveReviewSurveyResult } from '@services/surveyService';
import 'survey-core/i18n/french';

const props = defineProps({
  surveySchema: {
    type: Object,
    required: true,
  },
  reviewUid: {
    type: String,
    required: true,
  },
  previewMode: {
    type: Boolean,
    default: false,
    required: false,
  },
  lk: {
    type: String,
    required: false,
    default: null,
  },
});

const survey = ref(null);

onMounted(() => {
  if (props.lk) {
    slk(props.lk);
  }
  const surveyJson = props.surveySchema.content;
  surveyJson['checkErrorsMode'] = 'onValueChanged';
  survey.value = new Model(surveyJson);
  survey.value.onComplete.add(surveyComplete);
});

const surveyComplete = async (survey, options) => {
  if (!props.previewMode) {
    options.showSaveInProgress();
    const response = await saveReviewSurveyResult({ result: survey.data, reviewUid: props.reviewUid });
    if (response.ok) {
      options.showSaveSuccess();
      // Alternatively, you can clear all messages:
      // options.clearSaveMessages();
    } else {
      options.showSaveError(response.error);
    }
  } else {
    options.showSaveSuccess('Les résultats ne sont pas enregistrés lors de la prévisualisation.');
  }
};
</script>

<style setup></style>
