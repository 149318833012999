<template>
  <div class="bg-scroll">
    <div id="flash" class="absolute z-50">
      <div class="fixed right-4 top-20 mt-2 grid grid-cols-1 gap-4">
        <SimpleToast v-show="showError" index="0" model="danger" :message="errorMessage" @close="handleCloseErrorToast"></SimpleToast>
        <SimpleToast v-show="showSuccess" index="0" model="success" :message="successMessage" @close="handleCloseSuccessToast"></SimpleToast>
      </div>
    </div>
    <div class="min-h-52 min-w-52 flex justify-center overflow-hidden">
      <TurningLoader v-if="loading" class="flex h-full w-full items-center justify-center" />
      <img
        v-if="!loading && shareableImageSrc"
        :id="`shareableImage${shareable.id}`"
        ref="shareableImage"
        :src="shareableImageSrc"
        alt="Image récupérée"
        class="max-h-full max-w-full object-cover"
      />
    </div>
    <div class="relative">
      <div class="flex items-center justify-center gap-4">
        <button
          class="group mr-1 flex h-8 items-center justify-center rounded-full bg-blue px-3 py-1 text-xs text-white hover:bg-blue-dark disabled:cursor-not-allowed disabled:bg-blue-lighter"
          :disabled="loading"
          @click="toggleEmailForm"
        >
          <hero-icon name="MailHeroIcon" classes="mr-1" size="small"></hero-icon>
          <div class="text-left">{{ $t('reviews.share.modal.email_hint') }}</div>
        </button>
        <button
          class="group mr-1 flex h-8 items-center justify-center rounded-full bg-indigo px-3 py-1 text-xs text-white hover:bg-blue-dark hover:bg-indigo-dark hover:no-underline hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-blue-lighter"
          :disabled="loading"
          @click="downloadImage"
        >
          <hero-icon name="CloudDownloadHeroIcon" classes="mr-1" size="small"></hero-icon>
          <div class="text-left">{{ $t('reviews.share.modal.download_hint') }}</div>
        </button>
      </div>

      <div v-if="showEmailForm" ref="emailForm" class="mt-5">
        <textarea
          v-model="recipientEmails"
          :disabled="loadingEmail"
          :autofocus="true"
          class="block w-full rounded-lg border border-gray-light bg-gray-lighter p-2.5 text-sm text-gray-dark focus:border-blue-light focus:ring-blue-light"
          :placeholder="$t('reviews.share.modal.input_email')"
          :class="loadingEmail ? 'opacity-20' : ''"
        />
        <div class="mt-3 flex items-center justify-end">
          <button
            class="mr-2 flex w-32 items-center justify-center rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-textSecondary hover:border hover:border-primary hover:text-primary hover:no-underline"
            :disabled="disableSubmitButton"
            @click="showEmailForm = !showEmailForm"
          >
            <div class="mr-2">{{ $t('reviews.share.modal.cancel') }}</div>
          </button>
          <button
            class="flex items-center rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
            :disabled="disableSubmitButton"
            @click="shareShareable"
          >
            <div class="mr-2">{{ $t('reviews.share.modal.send') }}</div>
            <hero-icon name="PaperPlaneIonIcon" size="small"></hero-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue';
import TurningLoader from '../loaders/TurningLoader.vue';
import SimpleToast from '../toasts/SimpleToast.vue';
import { shareByEmail } from '@services/sharedAssetService';
import { loadTemplateAndGenerateImage } from '@lib/imageAndPdfService';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const props = defineProps({
  shareable: {
    type: Object,
    required: true,
  },
  shareableType: {
    type: String,
    required: true,
  },
});

const loading = ref(true);
const recipientEmails = ref('');
const loadingEmail = ref(false);
const showEmailForm = ref(false);
const emailForm = ref(null);
const showError = ref(false);
const errorMessage = ref('');
const showSuccess = ref(false);
const successMessage = ref('');
const shareableImageSrc = ref('');

onMounted(async () => {
  try {
    loading.value = true;

    // Create and inject image on display Modal
    const dataUri = await loadTemplateAndGenerateImage(props.shareable, props.shareableType);
    shareableImageSrc.value = dataUri;

    loading.value = false;
  } catch (error) {
    console.log(error);
    displayErrorMessage(t('reviews.share.modal.generate.error'));
  }
});

const shareShareable = async () => {
  try {
    loadingEmail.value = true;

    const res = await shareByEmail({
      shareableId: props.shareable.id,
      recipientEmails: recipientEmails.value,
      shareableType: props.shareableType,
      base64Image: shareableImageSrc.value || null,
    });
    displaySuccessMessage(res.data.message);
  } catch (error) {
    displayErrorMessage(error.message);
  } finally {
    loadingEmail.value = false;
  }
};

const downloadImage = () => {
  if (!shareableImageSrc.value) {
    displayErrorMessage(t('reviews.share.modal.download.no_image'));
    return;
  }

  const link = document.createElement('a');
  link.href = shareableImageSrc.value;
  link.download = 'downloaded-image.png';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  displaySuccessMessage(t('reviews.share.modal.download.success'));
};

const displaySuccessMessage = message => {
  showSuccess.value = true;
  successMessage.value = message;
};

const displayErrorMessage = message => {
  loading.value = false;
  showError.value = true;
  errorMessage.value = message;
};

const handleCloseErrorToast = () => {
  showError.value = false;
  errorMessage.value = '';
};

const handleCloseSuccessToast = () => {
  showSuccess.value = false;
  successMessage.value = '';
};

const disableSubmitButton = computed(() => {
  return recipientEmails.value == undefined || loadingEmail.value;
});

const toggleEmailForm = () => {
  showEmailForm.value = !showEmailForm.value;

  if (showEmailForm.value) {
    nextTick(() => {
      emailForm.value?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }
};
</script>
