<template>
  <div>
    <button
      :class="buttonClasses"
      :data-tooltip-target="`tooltip-configure-${surveySchema.id}`"
      data-tooltip-placement="bottom"
      @click="showEditModal = true"
    >
      <hero-icon :name="buttonIconName" size="default"></hero-icon>
    </button>
    <default-tooltip
      :id="`tooltip-configure-${surveySchema.id}`"
      classes="bg-blue-lighter text-blue-500"
      :message="$t('survey_schemas.edit.modal.edit_tooltip')"
    ></default-tooltip>
    <Modal v-model="showEditModal" :title="$t('survey_schemas.edit.modal.title')" wrapper-class="modal-wrapper" modal-class="modal modal-lg">
      <div v-show="isError" class="mb-4 flex items-center justify-between rounded-lg bg-red-50 p-4 text-sm text-danger" role="alert">
        <hero-icon name="AlertOutlinetIonIcon" size="large" classes="pt-1 mr-2"></hero-icon>
        <p class="text-danger">{{ errorMessage }}</p>
      </div>
      <div v-show="isSuccessMessage" class="mb-4 flex items-center justify-between rounded-lg bg-green-50 p-4 text-sm text-green-dark" role="alert">
        <hero-icon name="CheckCircleOutlineHeroIcon" size="large" classes="pt-1 mr-2"></hero-icon>
        <p class="text-green-dark">{{ successMessage }}</p>
      </div>
      <div>
        <div>
          <div class="mb-8">
            <label for="name" class="mb-2 block text-sm font-medium text-textPrimary">{{ $t('survey_schemas.edit.modal.name') }}</label>
            <input
              id="name"
              v-model="$v.name.$model"
              type="text"
              class="focus:shadow-focus form-input h-10 w-96 overflow-hidden rounded-lg border-gray-200 bg-white px-4 text-sm font-medium text-textPrimary placeholder:text-textSecondary hover:drop-shadow-md"
              required
            />
            <p v-if="$v.name.$error" class="text-xs text-danger">{{ $t('survey_schemas.edit.modal.two_letters_min') }}</p>
          </div>
          <div v-if="!surveySchema.published_at" class="mb-8 flex flex-col items-start gap-4">
            <div class="text-sm font-medium text-textPrimary">{{ $t('survey_schemas.edit.modal.is_model') }}</div>
            <div class="ml-8 flex items-start gap-6">
              <div class="flex items-center">
                <input
                  id="is-model-yes"
                  v-model="isModel"
                  type="radio"
                  name="is-model-yes"
                  value="true"
                  class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                />
                <label for="is-model-yes" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  <span>
                    {{ $t('survey_schemas.edit.modal.yes_choice') }}
                  </span>
                </label>
              </div>
              <div class="flex items-center">
                <input
                  id="is-model-no"
                  v-model="isModel"
                  type="radio"
                  name="is-model-no"
                  value="false"
                  class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                />
                <label for="is-model-no" class="ms-2 flex items-center text-sm font-medium text-gray-900 dark:text-gray-300">
                  <span>
                    {{ $t('survey_schemas.edit.modal.no_choice') }}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div v-if="showAppModel" class="mb-8 flex flex-col items-start gap-4">
            <div class="text-sm font-medium text-textPrimary">{{ $t('survey_schemas.edit.modal.is_app_model') }}</div>
            <div class="ml-8 flex items-start gap-6">
              <div class="flex items-center">
                <input
                  id="is-app-model-yes"
                  v-model="isAppModel"
                  type="radio"
                  name="is-app-model-yes"
                  value="true"
                  class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                />
                <label for="is-app-model-yes" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  <span>
                    {{ $t('survey_schemas.edit.modal.yes_choice') }}
                  </span>
                </label>
              </div>
              <div class="flex items-center">
                <input
                  id="is-app-model-no"
                  v-model="isAppModel"
                  type="radio"
                  name="is-app-model-no"
                  value="false"
                  class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                />
                <label for="is-app-model-no" class="ms-2 flex items-center text-sm font-medium text-gray-900 dark:text-gray-300">
                  <span>
                    {{ $t('survey_schemas.edit.modal.no_choice') }}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-10 flex items-center justify-end">
        <button
          class="rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-textSecondary hover:text-primary"
          @click="showEditModal = false"
        >
          {{ $t('buttons.cancel') }}
        </button>
        <button
          class="rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
          :disabled="$v.$invalid || isLoading"
          @click="handleUpdate"
        >
          <div v-if="isLoading" class="text-center">
            <TurningLoader text-color="text-textLight" size="sm" />
          </div>
          <div v-else>
            {{ $t('survey_schemas.edit.modal.submit_button') }}
          </div>
        </button>
      </div>
    </Modal>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { updateSurveySchema } from '@services/surveyService';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';

const { t } = useI18n();

const props = defineProps({
  currentClinicId: {
    type: Number,
    required: true,
  },
  surveySchema: {
    type: Object,
    required: true,
  },
  buttonClasses: {
    type: String,
    default: 'text-gray-400 hover:text-primary flex justify-center',
    required: false,
  },
  buttonIconName: {
    type: String,
    default: 'PencilOutlineHeroIcon',
    required: false,
  },
  canEditAppModelSelection: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const showEditModal = ref(false);
const isLoading = ref(false);
const isError = ref(false);
const isSuccessMessage = ref(false);
const errorMessage = ref(null);
const successMessage = ref(null);
const name = ref(null);
const isModel = ref(false);
const isAppModel = ref(false);

onMounted(() => {
  name.value = props.surveySchema.name;
  isModel.value = props.surveySchema.is_model;
  isAppModel.value = props.surveySchema.is_app_model;
});

const rules = computed(() => {
  return {
    name: {
      minLength: minLength(2),
      required,
    },
  };
});

const showAppModel = computed(() => {
  return !props.surveySchema.published_at && props.canEditAppModelSelection && (isModel.value === 'true' || isModel.value === true);
});

watch(isModel, isModel => {
  if (isModel === 'false' || isModel === false) {
    isAppModel.value = false;
  }
});

const $v = useVuelidate(rules, { name });

const handleUpdate = async () => {
  try {
    isLoading.value = true;
    const result = await updateSurveySchema({
      currentClinicId: props.currentClinicId,
      surveySchemaId: props.surveySchema.id,
      params: { name: name.value, is_model: isModel.value, is_app_model: isAppModel.value },
    });
    if (result?.ok) {
      successMessage.value = result.response.data.message;
      isSuccessMessage.value = true;
      setTimeout(() => {
        isSuccessMessage.value = false;
        successMessage.value = null;
        isLoading.value = false;
        showEditModal.value = false;
        const path = `/clinics/${props.currentClinicId}/survey_schemas`;
        window.location.href = path;
      }, 1500);
    } else {
      errorMessage.value =
        result?.response?.status === 404 ? t('survey_schemas.edit.modal.unfound_page') : result?.error || t('survey_schemas.edit.modal.error');
      isLoading.value = false;
      isError.value = true;
      setTimeout(() => {
        isError.value = false;
        errorMessage.value = null;
      }, 3000);
    }
  } catch (error) {
    errorMessage.value = `Une erreur est survenue. Veuillez essayer à nouveau. (${error.message})`;
    isError.value = true;
    isLoading.value = false;
    setTimeout(() => {
      isError.value = false;
      errorMessage.value = null;
    }, 3000);
  }
};
</script>
<style setup>
:root {
  --vs-border-radius: 8px;
  --vs-border-color: #d1d5db;
  --vs-selected-bg: #e1effe;
  --vs-selected-border-color: #e1effe;
  --vs-selected-color: #4691f7;
  --vs-selected-padding: 3px;
  --vs-controls-color: #4691f7;
  --vs-font-size: 14px;
  --vs-dropdown-z-index: 1000;
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 120px;
  --vs-dropdown-box-shadow: 0px 3px 6px 0px var(--vs-colors--darkest);
}
</style>
