<template>
  <div class="flex w-full items-start justify-between">
    <div class="review-card-header mb-4 flex items-center justify-start">
      <div class="avatar relative mr-4 h-10 w-10">
        <div
          v-if="!review.unknownAuthor"
          class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center overflow-hidden rounded-full dark:bg-gray-600"
          :class="avatarCircleColor"
        >
          <a :href="patientPath" class="font-medium text-gray-600 hover:no-underline dark:text-gray-300">{{ review.author[0] }}</a>
        </div>
        <div v-else class="absolute left-0 top-0 h-10 w-10 overflow-hidden rounded-full bg-gray-100 dark:bg-gray-600">
          <hero-icon name="AvatarHeroIcon" classes="absolute top-1 -left-1 h-12 w-12 text-gray-400"></hero-icon>
        </div>
      </div>
      <div class="bloc-details">
        <div class="display-name mb-1">
          <a
            :href="patientPath"
            class="cursor-pointer text-sm font-medium hover:no-underline"
            :class="{ 'text-gray-700': !review.unknownAuthor, 'text-gray-400': review.unknownAuthor }"
            >{{ review.author }}
          </a>
        </div>
        <div class="sub-block-details flex h-full items-center justify-start">
          <span
            class="mr-2 rounded-full px-2.5 py-0.5 text-center text-xs font-medium"
            :class="isSatisfied ? 'bg-green-100 text-green-800' : 'bg-pink-100 text-pink-800'"
            >{{ isSatisfied ? t('reviews.satisfied') : t('reviews.not_satisfied') }}</span
          >
          <span v-if="review.surveyName" class="mr-2 rounded-full bg-indigo-200 px-2.5 py-0.5 text-center text-xs font-medium text-indigo-800">
            {{ review.surveyName }}
          </span>
          <span v-if="review.tag" class="mr-2 rounded-full bg-gray-200 px-2.5 py-0.5 text-center text-xs font-medium text-textSecondary">
            {{ reviewTag }}
          </span>
          <div class="review-date flex h-full items-center">
            <p class="text-xs text-textLight">{{ review.reviewDisplayDate }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showStatus" class="bloc-status flex grow flex-col items-end justify-end pl-2">
      <select
        name="status"
        class="focus:shadow-focus form-select inline-flex shrink overflow-hidden rounded-full border-gray-300 bg-transparent pl-3 text-xs text-gray-500 hover:bg-white hover:drop-shadow-md"
        @change="handleUpdateReviewStatus"
      >
        <option v-for="status in statuses" :key="status[1]" :value="status[1]" :selected="status[1] === selectedStatus">
          {{ status[0] }}
        </option>
      </select>
      <div v-if="showUpdateStatusMessage">
        <span v-if="errorUpdateStatusMessage" class="mr-2 text-xs text-danger">{{ errorUpdateStatusMessage }}</span>
        <span v-if="successUpdateStatusMessage" class="mr-2 text-xs text-success">{{ successUpdateStatusMessage }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, onMounted } from 'vue';
import { updateReviewStatus } from '@services/reviewService';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  // @example:
  // { id:, author:, content:, comment:, avatarURl:, totalRatingNb:, rating:, reviewDisplayDate:, replyDisplayDate:, reply: ...}
  review: {
    type: Object,
    required: true,
  },
  statuses: {
    type: Array,
    required: true,
  },
  showStatus: {
    type: Boolean,
    default: false,
    required: false,
  },
  patientPath: {
    type: String,
    required: true,
  },
});

const avatarCircleColor = ref('');
const selectedStatus = ref('');
const successUpdateStatusMessage = ref('');
const errorUpdateStatusMessage = ref('');

onMounted(() => {
  avatarCircleColor.value = _.sample(['bg-gray-400', 'bg-primary', 'bg-secondary', 'bg-indigo', 'bg-info', 'bg-warning']);
  selectedStatus.value = props.review.status;
});

const showUpdateStatusMessage = computed(() => {
  return successUpdateStatusMessage.value !== '' || errorUpdateStatusMessage.value !== '';
});

const isSatisfied = computed(() => {
  return props.review.rating > 3;
});

const reviewTag = computed(() => {
  return _.capitalize(props.review.tag);
});

const handleUpdateReviewStatus = async event => {
  try {
    const status = event.target.value;
    await updateReviewStatus(props.review.id, { status });
    selectedStatus.value = status;
    successUpdateStatusMessage.value = t('reviews.update_review_status.success_message');
    setTimeout(function () {
      successUpdateStatusMessage.value = '';
    }, 1500);
  } catch (error) {
    errorUpdateStatusMessage.value = t('reviews.update_review_status.default_error_message');
    setTimeout(function () {
      errorUpdateStatusMessage.value = '';
    }, 1500);
  }
};
</script>
<style scoped>
select:not([size]) {
  padding-right: 2rem;
}
.form-select {
  padding-top: 4px;
  padding-bottom: 4px;
}
</style>
