<template>
  <div>
    <button
      id="clinicDropdownButton"
      data-tooltip-target="tooltip-current-clinic"
      type="button"
      class="inline-flex h-10 overflow-hidden rounded-lg bg-bgDefault px-4 hover:drop-shadow-md dark:bg-gray-600 dark:focus:ring-gray-600 md:w-64 lg:w-72 xl:w-96"
      aria-controls="clinicsDropdown"
      aria-expanded="false"
      data-dropdown-toggle="clinicsDropdown"
      data-dropdown-placement="bottom"
    >
      <span class="sr-only">Open clinics navigation menu</span>
      <div class="flex h-full w-full items-center justify-between">
        <div class="mr-1 hidden truncate whitespace-nowrap font-medium dark:text-gray-300 md:mr-4 md:block">
          <span class="text-primary">{{ currentClinic.name }}</span>
        </div>
        <hero-icon name="ChevronDownHeroIcon" size="default" classes="text-primary"></hero-icon>
      </div>
    </button>
    <div
      v-if="currentClinic.name.length > 35"
      id="tooltip-current-clinic"
      role="tooltip"
      class="tooltip invisible absolute z-10 inline-block rounded-lg bg-primary px-3 py-2 text-sm font-medium text-white opacity-0 shadow-sm transition-opacity duration-300 dark:bg-gray-700"
    >
      {{ currentClinic.name }}
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
    <div
      v-else
      id="tooltip-current-clinic"
      role="tooltip"
      class="tooltip invisible absolute z-10 inline-block rounded-lg bg-primary px-3 py-2 text-sm font-medium text-white opacity-0 shadow-sm transition-opacity duration-300 dark:bg-gray-700 md:hidden"
    >
      {{ currentClinic.name }}
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
    <!-- Clinics Dropdown menu -->
    <div
      id="clinicsDropdown"
      class="z-10 hidden w-80 list-none divide-y divide-gray-100 rounded-lg bg-white text-base drop-shadow-2xl dark:divide-gray-600 dark:bg-gray-700 md:w-72 lg:w-80 xl:w-96"
    >
      <div class="p-3">
        <label for="input-group-search" class="sr-only">{{ $t('navbar.clinics_dropdown.search_placeholder') }}</label>
        <div class="relative">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <hero-icon name="SearchHeroIcon" classes="w-4 h-4 text-gray-500 dark:text-gray-400"></hero-icon>
          </div>
          <input
            id="input-group-search"
            v-model="filterText"
            type="text"
            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2 pl-10 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            :placeholder="$t('navbar.clinics_dropdown.search_placeholder')"
          />
        </div>
      </div>
      <ul class="max-h-80 overflow-y-auto py-4" aria-labelledby="clinicDropdownButton">
        <li v-for="clinic in filteredClinics" :key="clinic.id" :class="dropdownItemBackgroundColorClass(clinic.id)">
          <a
            :href="clinic.href"
            class="block cursor-pointer px-6 py-2 text-sm text-gray-700 hover:bg-blue-100 hover:text-primary hover:no-underline dark:text-primary dark:hover:bg-gray-600 dark:hover:text-white"
            >{{ clinic.name }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  activeClinicsWithRoutes: {
    type: Object,
    required: true,
  },
  currentClinic: {
    type: Object,
    required: true,
  },
});

const filterText = ref('');

const filteredClinics = computed(() => {
  let filter = filterText.value.trim();
  if (!filter.length) return props.activeClinicsWithRoutes;
  return props.activeClinicsWithRoutes.filter(clinic => clinic.name.toLowerCase().includes(filter.toLowerCase()));
});

const dropdownItemBackgroundColorClass = clinicId => {
  return clinicId === props.currentClinic.id ? 'bg-bgDefault' : '';
};
</script>

<style scoped></style>
