<template>
  <div class="flex h-full flex-col justify-between">
    <div>
      <h2>{{ $t('import_patients.establishment_selection_form.establishments_question') }}</h2>
      <form class="mt-12 text-left" @submit.prevent>
        <div
          v-if="showError"
          class="mb-5 flex w-full items-center justify-between border-l-2 border-danger bg-red-100 p-4 text-sm text-danger"
          @click="handleCloseAlert"
        >
          <span class="mr-2 text-red-dark">{{ errorMessage }}</span>
          <hero-icon name="CloseHeroIcon" classes="hover:cursor-pointer" size="small"></hero-icon>
        </div>
        <fieldset>
          <div class="form-group mb-8">
            <label v-if="clinics.length === 0" class="mb-2 block font-medium tracking-wide text-gray-700">{{
              $t('import_patients.establishment_selection_form.no_managed_establishment')
            }}</label>
            <div v-else>
              <div class="mr-16 flex items-center justify-end gap-2">
                <button class="mb-2 text-right text-xs leading-normal text-blue hover:underline" @click="addAllEstablishments">Tout ajouter</button>
                <button class="mb-2 text-right text-xs leading-normal text-blue hover:underline" @click="removeAllEstablishments">
                  Tout enlever
                </button>
              </div>
              <div class="flex items-center justify-start space-x-3 text-sm font-medium">
                <v-select
                  v-model.trim="$v.selectedClinics.$model"
                  multiple
                  autocomplete
                  :close-on-select="false"
                  :deselect-from-dropdown="true"
                  :options="clinics"
                  :reduce="clinic => clinic['id']"
                  label="name"
                  class="w-full"
                  clearable
                  placeholder="Sélectionnez dans la liste..."
                />
                <div class="w-12">
                  <p v-if="$v.selectedClinics.validArray.$invalid" class="text-xs leading-normal text-danger">{{ $t('users.required') }}</p>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
    <div class="mt-40 flex items-center justify-center">
      <button
        class="mr-2 flex w-32 items-center justify-center rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-textSecondary hover:border hover:border-primary hover:text-primary hover:no-underline"
        @click="navigateToPage(beforePath)"
      >
        {{ $t('buttons.cancel') }}
      </button>
      <button
        class="flex w-32 items-center justify-center rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
        :disabled="$v.$invalid"
        @click="handleSubmit"
      >
        <div>{{ $t('buttons.next') }}</div>
        <hero-icon name="ChevronRightIonIcon" size="small"></hero-icon>
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';
import { useImportPatientsStore } from '@stores/importPatients.js';
const store = useImportPatientsStore();

const { t } = useI18n();

const props = defineProps({
  clinics: {
    type: Array,
    required: true,
  },
  beforePath: {
    type: String,
    required: true,
  },
  currentClinic: {
    type: Object,
    required: false,
    default: () => {
      return {};
    },
  },
});
const emit = defineEmits(['next-step']);

const errorMessage = ref('');
const selectedClinics = ref([]);
const showClinicsDropdown = ref(false);
const showError = ref(false);

onMounted(() => {
  showClinicsDropdown.value = false;
  initErrorMessage();
});

const validArray = item => item.length > 0;

const rules = computed(() => {
  return {
    selectedClinics: {
      validArray,
    },
  };
});

const $v = useVuelidate(rules, { selectedClinics });

const initErrorMessage = () => {
  errorMessage.value = t('users.standard_user_adding_message');
};

const handleCloseAlert = () => {
  showError.value = false;
  initErrorMessage();
};

const handleSubmit = () => {
  storeSettings();
  emit('next-step');
};

const addAllEstablishments = () => {
  selectedClinics.value = props.clinics.map(obj => obj.id);
};

const removeAllEstablishments = () => {
  selectedClinics.value = [];
};

const storeSettings = () => {
  store.updateEstablishments({
    establishments: selectedClinics.value,
  });
};

const navigateToPage = path => {
  window.location.href = path;
};
</script>
<style setup>
:root {
  --vs-border-radius: 8px;
  --vs-border-color: #d1d5db;
  --vs-selected-bg: #e1effe;
  --vs-selected-border-color: #e1effe;
  --vs-selected-color: #4691f7;
  --vs-controls-color: #4691f7;
  --vs-font-size: 14px;
  --vs-dropdown-z-index: 1000;
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 120px;
  --vs-dropdown-box-shadow: 0px 3px 6px 0px var(--vs-colors--darkest);
}
</style>
