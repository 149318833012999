<template>
  <div class="relative h-full">
    <div id="flash" class="absolute z-50">
      <div class="fixed right-4 top-40 mt-2 grid grid-cols-1 gap-4">
        <SimpleToast v-if="showToast" index="1" model="info" :message="message" @close="handleCloseToast"></SimpleToast>
      </div>
    </div>
    <SurveyCreatorComponent :model="creator" />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import { SurveyCreatorModel } from 'survey-creator-core';
import { SurveyCreatorComponent } from 'survey-creator-vue';
import { updateSurveySchema, uploadFiles } from '@services/surveyService';
import SimpleToast from '@components/shared/toasts/SimpleToast.vue';
import markdownit from 'markdown-it';
import DOMPurify from 'dompurify';
import { slk } from 'survey-core';
// import { editorLocalization } from 'survey-creator-core';
import 'survey-creator-core/i18n/french';

const props = defineProps({
  showLogicTab: {
    type: Boolean,
    required: false,
    default: true,
  },
  isAutoSave: {
    type: Boolean,
    required: false,
    default: false,
  },
  surveySchema: {
    type: Object,
    required: true,
  },
  currentClinicId: {
    type: Number,
    required: true,
  },
  lk: {
    type: String,
    required: false,
    default: null,
  },
});

if (props.lk) {
  slk(props.lk);
}

const showToast = ref(false);
const message = ref('');

// editorLocalization.currentLocale = 'fr';
const creatorOptions = {
  showLogicTab: props.showLogicTab,
  isAutoSave: props.isAutoSave,
  showThemeTab: true,
};

const creator = new SurveyCreatorModel(creatorOptions);
creator.locale = 'fr';
creator.text = window.localStorage.getItem('survey-json') || JSON.stringify(props.surveySchema.content);

creator.saveSurveyFunc = async (saveNo, callback) => {
  const response = await updateSurveySchema({
    surveySchemaId: props.surveySchema.id,
    params: { content: creator.JSON },
    currentClinicId: props.currentClinicId,
  });
  if (response.ok) {
    callback(saveNo, true);
  } else {
    callback(saveNo, false);
  }
};

creator.onUploadFile.add(async (_, options) => {
  const formData = new FormData();
  if (options.files.length > 0) {
    const file = options.files[0]; // Take the first file
    formData.append('file', file); // Change the key to 'file' for single file
    const result = await uploadFiles({ formData, currentClinicId: props.currentClinicId, surveySchemaId: props.surveySchema.id });
    if (result.ok) {
      message.value = result.message;
      showToast.value = true;
      options.callback('success', result.file.url);
    } else {
      message.value = result.error;
      showToast.value = true;
      options.callback('error');
    }
  } else {
    options.callback('error');
  }
});

const converter = markdownit({
  html: true, // Support HTML tags in the source (unsafe, see documentation)
});

creator.onSurveyInstanceCreated.add((_, options) => {
  options.survey.onTextMarkdown.add((_, options) => {
    if (!options.text) return;
    // Convert Markdown to HTML
    let str = converter.renderInline(options.text);
    // Sanitize the HTML markup using DOMPurify
    const sanitizedHTML = DOMPurify.sanitize(str);
    // Set HTML markup to render
    options.html = sanitizedHTML;
  });
});

const handleCloseToast = () => {
  showToast.value = false;
  message.value = '';
};
</script>
<style setup>
:root {
  --ctr-menu-item-border-color-selected: #4691f7;
  --sjs-primary-backcolor: #4691f7;
  --primary: #4691f7;
  /* --primary: #74c140; */
}
</style>
