<template>
  {{ formattedTime }}
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

dayjs.extend(relativeTime);

const props = defineProps({
  dateText: {
    type: String,
    required: true,
  },
});

const { locale } = useI18n();

const updateLocale = () => {
  dayjs.locale(locale.value);
};

const formattedTime = computed(() => {
  updateLocale();

  const past = dayjs(props.dateText);

  if (!past.isValid()) {
    return '-';
  }

  let timeString = past.fromNow(true);

  return timeString;
});
</script>
