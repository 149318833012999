<template>
  <div>
    <button :class="buttonClasses" @click="showExportModal = true">
      <hero-icon :name="buttonIconName" classes="pt-1 mr-2 h-6"></hero-icon>
      {{ $t('survey_schemas.report.export.button') }}
    </button>
    <Modal
      v-model="showExportModal"
      title="Exporter un rapport"
      wrapper-class="modal-wrapper"
      modal-class="modal modal-lg"
      @before-close="beforeCloseModal"
    >
      <div v-show="isError" class="mb-4 flex items-center justify-between rounded-lg bg-red-50 p-4 text-sm text-danger" role="alert">
        <hero-icon name="AlertOutlinetIonIcon" size="large" classes="pt-1 mr-2"></hero-icon>
        <p class="text-danger">{{ errorMessage }}</p>
      </div>
      <div v-show="isSuccessMessage" class="mb-4 flex items-center justify-between rounded-lg bg-green-50 p-4 text-sm text-green-dark" role="alert">
        <hero-icon name="CheckCircleOutlineHeroIcon" size="large" classes="pt-1 mr-2"></hero-icon>
        <p class="text-green-dark">{{ successMessage }}</p>
      </div>
      <div v-if="isLoading" class="mt-10 text-center">
        <TurningLoader :message="$t('loading')" text-color="text-textLight" />
      </div>
      <div v-else>
        <div class="w-12/12">
          <div class="mb-8 flex flex-wrap items-center justify-start gap-2">
            <label for="exportTitle" class="mr-4 block text-sm font-medium text-textPrimary">{{
              $t('survey_schemas.report.export.title.label')
            }}</label>
            <div class="relative grow">
              <input
                id="exportTitle"
                v-model="v$.exportTitle.$model"
                type="text"
                class="focus:shadow-focus form-input h-10 w-full overflow-hidden rounded-lg border-gray-200 bg-white px-4 text-xs font-medium text-textPrimary placeholder:text-textSecondary hover:drop-shadow-md"
                required
              />
              <p v-if="v$.exportTitle.$error" class="absolute -bottom-5 text-xs text-danger">{{ $t('survey_schemas.report.export.title.error') }}</p>
            </div>
          </div>
          <div class="flex items-center">
            <div class="w-32 text-sm font-medium text-textPrimary">Exporter en</div>
            <div class="flex items-center gap-6">
              <div class="flex items-center">
                <input
                  id="export-pdf-portrait"
                  v-model="exportType"
                  type="radio"
                  name="exportType"
                  value="pdf-portrait"
                  class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                />
                <label for="export-pdf-portrait" class="font-base ms-2 flex items-center text-sm text-gray-900 dark:text-gray-300">{{
                  $t('survey_schemas.report.export.pdf_portrait')
                }}</label>
              </div>
              <div class="flex items-center">
                <input
                  id="export-pdf-landscape"
                  v-model="exportType"
                  type="radio"
                  name="exportType"
                  value="pdf-landscape"
                  class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                />
                <label for="export-pdf-landscape" class="font-base ms-2 flex items-center text-sm text-gray-900 dark:text-gray-300">{{
                  $t('survey_schemas.report.export.pdf_landscape')
                }}</label>
              </div>
              <div class="flex items-center">
                <input
                  id="export-jpg"
                  v-model="exportType"
                  type="radio"
                  name="exportType"
                  value="jpg"
                  class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                />
                <label for="export-jpg" class="font-base ms-2 flex items-center text-sm text-gray-900 dark:text-gray-300">{{
                  $t('survey_schemas.report.export.image')
                }}</label>
              </div>
            </div>
          </div>
          <div class="mt-4 flex items-start justify-start gap-4">
            <div class="grow">
              <hero-icon name="InformationIonIcon" size="large" classes="text-gray"></hero-icon>
            </div>
            <p class="text-pretty text-xs text-textSecondary">
              {{ $t('survey_schemas.report.export.hint') }}
            </p>
          </div>
        </div>
      </div>
      <div class="mt-10 flex items-center justify-end">
        <button
          class="rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-textSecondary hover:text-primary"
          @click="handleClose"
        >
          {{ $t('buttons.cancel') }}
        </button>
        <button
          class="rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
          :class="{ 'cursor-not-allowed opacity-75': disableExportButton }"
          :disabled="disableExportButton"
          @click="handleExport"
        >
          {{ $t('reviews.bad.submit_export') }}
        </button>
      </div>
    </Modal>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';
import { required, minLength } from '@vuelidate/validators';
import { exportToJpg, exportContainerToPdf, exportToPdfOneGraphPerPage } from '@lib/imageAndPdfService';

const { t } = useI18n();

const props = defineProps({
  selector: {
    type: String,
    required: false,
    default: '.sa-visualizer__content',
  },
  buttonClasses: {
    type: String,
    default:
      'flex items-center rounded-full border border-transparent bg-transparent px-3 py-2 text-sm text-textSecondary hover:border-primary hover:text-primary hover:no-underline',
    required: false,
  },
  buttonIconName: {
    type: String,
    default: 'CloudDownloadOutlineHeroIcon',
    required: false,
  },
});

const showExportModal = ref(false);
const isLoading = ref(false);
const isError = ref(false);
const errorMessage = ref('');
const isSuccessMessage = ref(false);
const successMessage = ref('');
const exportType = ref('pdf-portrait');
const exportTitle = ref(null);

onMounted(() => {
  initErrorMessage();
});

const rules = computed(() => {
  return {
    exportTitle: {
      minLength: minLength(2),
      required,
    },
  };
});

const initErrorMessage = () => {
  errorMessage.value = t('survey_schemas.report.export.error');
};

const v$ = useVuelidate(rules, { exportTitle });

const disableExportButton = computed(() => {
  return v$.value.$invalid || isBlank(exportTitle.value) || isLoading.value == true;
});

const isBlank = value => {
  return value == null || /^\s*$/.test(value);
};

const handleExport = async () => {
  isLoading.value = true;
  try {
    const element = document.querySelector(props.selector);
    switch (exportType.value) {
      case 'jpg':
        exportToJpg(element, exportTitle.value);
        break;
      case 'pdf-portrait':
        exportContainerToPdf({
          container: element,
          marginInMm: 15,
          title: exportTitle.value,
          landscape: false,
          minScale: 0.8,
          maxScale: 1.2,
          gapInMm: 5,
        });
        break;
      case 'pdf-landscape':
        exportContainerToPdf({
          container: element,
          title: exportTitle.value,
          landscape: true,
          marginInMm: 15,
          minScale: 0.8,
          maxScale: 1.5,
          gapInMm: 10,
        });
        break;
      default:
        exportToPdfOneGraphPerPage(element, exportTitle.value);
        break;
    }
  } catch (error) {
    // Code to handle the error
  } finally {
    isLoading.value = false;
  }
};

const beforeCloseModal = () => {
  isLoading.value = false;
  initErrorMessage();
  isError.value = false;
  isSuccessMessage.value = false;
};

const handleClose = () => {
  showExportModal.value = false;
};
</script>
