<template>
  <div class="w-full px-4">
    <div v-if="displayMessage" class="mx-auto flex items-center justify-center rounded-xl px-3 py-2" :class="messageClasses">
      <p class="text-center text-xs text-white">{{ message }}</p>
    </div>
    <div v-else class="relative flex w-full justify-center">
      <button
        id="refresh-perf-button"
        type="button"
        :data-tooltip-target="tooltipId"
        data-tooltip-placement="right"
        class="rounded-full border border-gray-medium bg-transparent p-2 text-sm text-gray hover:border-primary hover:bg-primary hover:text-white"
        @click="handleRefresh"
      >
        <hero-icon name="SyncHeroIcon" size="default"></hero-icon>
      </button>
      <DefaultTooltip :id="tooltipId" classes="bg-blue-lighter text-blue-500 text-xs px-2" message="Rafraichir" />
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, onUpdated } from 'vue';
import { initTooltips } from 'flowbite';
import axios from 'axios';
import { handleErrorFromRequest } from '@services/handleErrorService.js';

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

const props = defineProps({
  clinicId: {
    type: Number,
    required: true,
  },
  tooltipId: {
    type: String,
    required: true,
  },
});

const message = ref('');
const displayMessage = ref(false);
const isError = ref(false);

onMounted(() => {
  initTooltips();
});

onUpdated(() => {
  initTooltips();
});

const messageClasses = computed(() => {
  return isError.value ? 'bg-danger' : 'bg-success';
});

const handleRefresh = async () => {
  try {
    const success = await refreshPerformanceHealthOnServer();
    if (success) {
      message.value = 'Calcul en cours, rafraîchissez la page dans un moment.';
    } else {
      isError.value = true;
      message.value = 'Veuillez réessayer plus tard.';
    }
  } catch (error) {
    isError.value = true;
    message.value = error.message;
  }
  displayMessage.value = true;
  setTimeout(() => {
    displayMessage.value = false;
    message.value = '';
    isError.value = false;
  }, 3000);
};

const refreshPerformanceHealthOnServer = async () => {
  try {
    const response = await axios.put(`/clinics/${props.clinicId}/refresh_health_performance`, {}, { headers: { Accept: 'application/json' } });
    return response.status === 200;
  } catch (error) {
    handleErrorFromRequest(error);
  }
};
</script>

<style lang="css" scoped>
#toggle-fs {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  z-index: 2;
}
</style>
