<template>
  <div class="flex h-full flex-col justify-between">
    <div>
      <h2>{{ $t('import_patients.configure_sollicitation.title', virtualPatientsCount) }}</h2>
      <form class="mt-12 text-left" @submit.prevent>
        <div
          v-if="showError"
          class="mb-5 flex w-full items-center justify-between border-l-2 border-danger bg-red-100 p-4 text-sm text-danger"
          @click="handleCloseAlert"
        >
          <span class="mr-2 text-red-dark">{{ errorMessage }}</span>
          <hero-icon name="CloseHeroIcon" classes="hover:cursor-pointer" size="small"></hero-icon>
        </div>
        <fieldset>
          <div class="ml-4 sm:ml-6">
            <div v-if="showSendingOption" class="mb-8 flex flex-wrap items-center gap-4">
              <div class="w-40 text-sm font-semibold text-textPrimary">
                {{ $t('import_patients.configure_sollicitation.sollicitate_patients', virtualPatientsCount) }}
              </div>
              <div class="flex items-center gap-6">
                <div class="flex items-center">
                  <input
                    id="send-email"
                    v-model="v$.selectedCanal.$model"
                    type="radio"
                    name="canal"
                    value="email"
                    class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                  />
                  <label for="send-email" class="font-base ms-2 flex items-center text-sm text-gray-900 dark:text-gray-300">
                    {{ $t('import_patients.configure_sollicitation.by_email') }}
                    <span class="ml-2 flex h-full items-center text-primary">
                      <hero-icon name="MailHeroIcon" size="default"></hero-icon>
                    </span>
                  </label>
                </div>
                <div class="flex items-center" :class="{ 'cursor-not-allowed': !enableSmsOption }">
                  <input
                    id="send-sms"
                    v-model="v$.selectedCanal.$model"
                    :disabled="!enableSmsOption"
                    type="radio"
                    name="canal"
                    value="sms"
                    class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                    :class="{ 'cursor-not-allowed': !enableSmsOption }"
                  />
                  <label
                    for="send-sms"
                    class="ms-2 flex items-center text-sm font-medium"
                    :class="{ 'text-gray-900': enableSmsOption, 'text-gray-300': !enableSmsOption, 'cursor-not-allowed': !enableSmsOption }"
                  >
                    {{ $t('import_patients.configure_sollicitation.by_sms') }}
                    <span class="ml-2 flex h-full items-center" :class="{ 'text-primary': enableSmsOption, 'text-gray-300': !enableSmsOption }">
                      <hero-icon name="ChatBubblesHeroIcon" size="default"></hero-icon>
                    </span>
                  </label>
                </div>
                <div data-tooltip-target="tooltip-sms" data-tooltip-placement="bottom" class="h-5 w-5">
                  <hero-icon name="InformationOutlineIonIcon" classes="ml-2 text-gray-300 hover:text-primary" size="default"></hero-icon>
                </div>
                <default-tooltip
                  id="tooltip-sms"
                  classes="bg-blue-lighter text-blue-500 text-xs w-52"
                  :message="$t('import_patients.configure_sollicitation.sms_tooltip')"
                />
              </div>
            </div>
            <div v-else class="mb-4 text-sm font-medium text-textPrimary">
              {{ $t('import_patients.configure_sollicitation.patients_sollicitated_by_email_only') }}
            </div>
          </div>
        </fieldset>
        <fieldset>
          <div class="ml-4 sm:ml-6">
            <div class="mb-8 flex flex-col items-start gap-4">
              <div class="w-40 text-sm font-semibold text-textPrimary">{{ $t('import_patients.configure_sollicitation.send') }}</div>
              <div class="ml-8 flex flex-col items-start gap-4">
                <div class="flex items-center">
                  <input
                    id="moment-now"
                    v-model="v$.selectedMoment.$model"
                    type="radio"
                    name="moment"
                    value="now"
                    class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                  />
                  <label for="moment-now" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    <span>
                      {{ $t('import_patients.configure_sollicitation.now') }}
                    </span>
                  </label>
                </div>
                <div class="flex flex-wrap items-center gap-3">
                  <div class="flex items-center">
                    <input
                      id="moment-later"
                      v-model="v$.selectedMoment.$model"
                      type="radio"
                      name="moment"
                      value="later"
                      class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                    />
                    <label for="moment-later" class="ms-2 flex items-center text-sm font-medium text-gray-900 dark:text-gray-300">
                      <span>
                        {{ $t('import_patients.configure_sollicitation.later') }}
                      </span>
                    </label>
                  </div>
                  <div v-if="showDateSelection" class="ms-6 w-48">
                    <VueTailwindDatepicker
                      v-model="v$.selectedDate.$model"
                      i18n="fr"
                      as-single
                      :formatter="formatter"
                      :shortcuts="false"
                      :options="options"
                      :disable-date="disablePastDates"
                      :auto-apply="true"
                      placeholder="Choisir la date d'envoi"
                      input-classes="block h-10 rounded-lg border-gray-300 bg-white text-xs font-medium text-gray-500 hover:drop-shadow-md"
                    />
                  </div>
                  <p v-if="v$.selectedDate.$error" class="ms-6 text-xs text-danger">{{ $t('import_patients.configure_sollicitation.date_error') }}</p>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <div class="ml-4 sm:ml-6">
            <div class="mb-8 flex flex-col items-start gap-4">
              <div class="text-sm font-semibold text-textPrimary">
                {{ $t('import_patients.configure_sollicitation.this_sollicitation_will_let_patients', virtualPatientsCount) }}
              </div>
              <div class="ml-8 flex flex-col items-start gap-4">
                <div class="flex items-center">
                  <input
                    id="redirection-private-only"
                    v-model="v$.selectedRedirection.$model"
                    type="radio"
                    name="redirection"
                    value="private_only"
                    class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                  />
                  <label for="redirection-private-only" class="ms-2 flex items-center gap-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    <span>
                      {{ $t('import_patients.configure_sollicitation.give_a_review_on_mercidocteur_only') }}
                    </span>
                    <span
                      v-if="sollicitationType !== 'file_multiple_establishments'"
                      class="flex h-full items-center text-gray-medium hover:cursor-pointer hover:text-primary"
                    >
                      <hero-icon name="EyeHeroIcon" size="default" @click="previewGoogleEmailTemplate"></hero-icon>
                    </span>
                  </label>
                </div>
                <div class="flex items-center">
                  <input
                    id="redirection-google-only"
                    v-model="v$.selectedRedirection.$model"
                    type="radio"
                    name="redirection"
                    value="google_only"
                    class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                  />
                  <label for="redirection-google-only" class="ms-2 flex items-center gap-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    <span>
                      {{ $t('import_patients.configure_sollicitation.give_a_review_on_google_only') }}
                    </span>
                    <span
                      v-if="sollicitationType !== 'file_multiple_establishments'"
                      class="flex h-full items-center text-gray-medium hover:cursor-pointer hover:text-primary"
                    >
                      <hero-icon name="EyeHeroIcon" size="default" @click="previewGoogleEmailTemplate"></hero-icon>
                    </span>
                  </label>
                </div>
                <div class="flex items-center">
                  <input
                    id="redirection-google-and-private"
                    v-model="v$.selectedRedirection.$model"
                    type="radio"
                    name="redirection"
                    value="private_and_google"
                    class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                  />
                  <label
                    for="redirection-google-and-private"
                    class="ms-2 flex items-center gap-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    <span>
                      {{ $t('import_patients.configure_sollicitation.give_a_review_on_mercidocteur_and_google') }}
                    </span>
                    <span
                      v-if="sollicitationType !== 'file_multiple_establishments'"
                      class="flex h-full items-center text-gray-medium hover:cursor-pointer hover:text-primary"
                    >
                      <hero-icon name="EyeHeroIcon" size="default" @click="previewGoogleEmailTemplate"></hero-icon>
                    </span>
                  </label>
                </div>
                <div v-if="showSurveyRadioButton" class="flex items-center">
                  <input
                    id="redirection-survey"
                    v-model="v$.selectedRedirection.$model"
                    type="radio"
                    name="redirection"
                    value="survey"
                    class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                  />
                  <label for="redirection-survey" class="ms-2 flex items-center gap-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    <span>
                      {{ $t('import_patients.configure_sollicitation.respond_to_survey') }}
                    </span>
                    <span
                      v-if="sollicitationType !== 'file_multiple_establishments'"
                      class="flex h-full items-center text-gray-medium hover:cursor-pointer hover:text-primary"
                    >
                      <hero-icon name="EyeHeroIcon" size="default" @click="previewSurveyEmailTemplate"></hero-icon>
                    </span>
                  </label>
                </div>
                <div v-if="showSelectSurveys">
                  <select
                    id="surveys"
                    v-model="selectedSurvey"
                    class="ml-6 block h-10 w-full rounded-lg border border-gray-300 bg-white px-2.5 text-xs font-medium text-gray-500 hover:drop-shadow-md"
                  >
                    <option v-for="survey in surveySchemas" :key="'schema' + survey.id" :value="{ id: survey.id, type: 'survey_schema' }">
                      {{ capitalizedName(survey.name) }}
                    </option>
                    <option v-for="survey in surveys" :key="survey.id" :value="{ id: survey.id, type: 'survey' }">
                      {{ capitalizedName(survey.description) }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <div v-if="showEsatisOption" class="ml-4 sm:ml-6">
          <div class="mb-8 flex flex-col items-start gap-4">
            <div class="text-sm font-semibold text-textPrimary">
              {{ $t('import_patients.configure_sollicitation.send_esatis_email') }}
            </div>
            <div class="ml-8 flex items-start gap-6">
              <div class="flex items-center">
                <input
                  id="esatis-yes"
                  v-model="selectedEsatisEmailing"
                  type="radio"
                  name="esatis_emailing"
                  value="true"
                  class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                />
                <label for="esatis-yes" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  <span>
                    {{ $t('import_patients.configure_sollicitation.yes_choice') }}
                  </span>
                </label>
              </div>
              <div class="flex items-center">
                <input
                  id="esatis-no"
                  v-model="selectedEsatisEmailing"
                  type="radio"
                  name="esatis_emailing"
                  value="false"
                  class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                />
                <label for="esatis-no" class="ms-2 flex items-center text-sm font-medium text-gray-900 dark:text-gray-300">
                  <span>
                    {{ $t('import_patients.configure_sollicitation.no_choice') }}
                  </span>
                </label>
              </div>
              <span
                v-if="sollicitationType !== 'file_multiple_establishments'"
                class="flex h-full items-center text-gray-medium hover:cursor-pointer hover:text-primary"
              >
                <hero-icon name="EyeHeroIcon" size="default" @click="previewEsatisEmailTemplate"></hero-icon>
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="mt-16 flex items-center justify-center">
      <button
        v-if="sollicitationType !== 'file_multiple_establishments'"
        class="mr-2 flex w-32 items-center justify-center rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-textSecondary hover:border hover:border-primary hover:text-primary hover:no-underline"
        @click="navigateToPage(beforePath)"
      >
        {{ $t('buttons.cancel') }}
      </button>
      <button
        v-else
        class="mr-2 flex w-32 items-center justify-center rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-textSecondary hover:border hover:border-primary hover:text-primary hover:no-underline"
        @click="$emit('previous-step')"
      >
        <div class="flex h-full items-center">
          <hero-icon name="ChevronLeftIonIcon" size="small"></hero-icon>
        </div>
        {{ $t('buttons.previous') }}
      </button>
      <button
        class="flex w-32 items-center justify-center rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
        :disabled="v$.$invalid"
        @click="handleSubmit"
      >
        {{ $t('buttons.next') }}
        <hero-icon name="ChevronRightIonIcon" size="small"></hero-icon>
      </button>
    </div>
    <Modal
      v-model="showEmailTemplateModal"
      :title="emailPreviewModalTitle"
      wrapper-class="modal-wrapper"
      modal-class="modal modal-lg max-h-[650px] overflow-auto"
    >
      <EmailPreviewModal :clinic-id="currentClinic.id" :email-type="emailType" @close="showEmailTemplateModal = false" />
    </Modal>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import { useImportPatientsStore } from '@stores/importPatients.js';
import EmailPreviewModal from '@components/shared/modals/EmailPreviewModal.vue';

const store = useImportPatientsStore();

const { t } = useI18n();

const props = defineProps({
  clinics: {
    type: Array,
    required: true,
  },
  beforePath: {
    type: String,
    required: true,
  },
  sollicitationType: {
    type: String, // manual, file_one_establishment, file_multiple_establishments
    required: true,
  },
  currentClinic: {
    type: Object,
    required: false,
    default: () => {
      return {};
    },
  },
  surveys: {
    type: Array,
    required: false,
    default: () => {
      return [];
    },
  },
  surveySchemas: {
    type: Array,
    required: false,
    default: () => {
      return [];
    },
  },
  esatisActivated: {
    type: Boolean,
    required: false,
    default: false,
  },
  showSendingOption: {
    type: Boolean,
    required: false,
    default: true,
  },
  enableSmsOption: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const selectedCanal = ref('email');
const selectedMoment = ref('now');
const selectedRedirection = ref('');
const selectedEsatisEmailing = ref(false);
const selectedDate = ref('');
const errorMessage = ref('');
const selectedSurvey = ref(null);
const showError = ref(false);
const showDateSelection = ref(false);
const formatter = ref({ date: 'DD/MM/YYYY', month: 'MMM' });
const options = ref({
  shortcuts: {
    today: "Aujourd'hui",
    yesterday: 'Hier',
    past: period => `${period} derniers jours`,
    currentMonth: 'Mois en cours',
    pastMonth: 'Mois précédent',
  },
  footer: {
    apply: 'Appliquer',
    cancel: 'Annuler',
  },
});
const emailType = ref('google');
const showEmailTemplateModal = ref(false);

const emit = defineEmits(['next-step', 'previous-step']);

onMounted(() => {
  initErrorMessage();
  setDefaultRedirection();
  selectedSurvey.value = props.surveySchemas[0]
    ? { id: props.surveySchemas[0].id, type: 'survey_schema' }
    : props.surveys[0]
      ? { id: props.surveys[0].id, type: 'survey' }
      : null;
});

watch(selectedMoment, () => {
  if (selectedMoment.value === 'later') {
    showDateSelection.value = true;
  } else {
    showDateSelection.value = false;
  }
});

watch(showEmailTemplateModal, () => {
  if (showEmailTemplateModal.value) {
    document.body.classList.add('overflow-hidden');
  } else {
    document.body.classList.remove('overflow-hidden');
  }
});

const showSelectSurveys = computed(() => {
  return selectedRedirection.value === 'survey';
});

const disablePastDates = date => {
  return date <= new Date();
};

const virtualPatientsCount = computed(() => {
  return props.sollicitationType == 'manual' ? 1 : 2;
});

const rules = computed(() => {
  if (selectedMoment.value === 'later') {
    return {
      selectedCanal: {
        required,
      },
      selectedDate: {
        required,
      },
      selectedMoment: {
        required,
      },
      selectedRedirection: {
        required,
      },
    };
  } else {
    return {
      selectedCanal: {
        required,
      },
      selectedMoment: {
        required,
      },
      selectedDate: {},
      selectedRedirection: {
        required,
      },
    };
  }
});

const v$ = useVuelidate(rules, { selectedRedirection, selectedCanal, selectedMoment, selectedDate });

const initErrorMessage = () => {
  errorMessage.value = t('users.standard_user_adding_message');
};

const setDefaultRedirection = () => {
  selectedRedirection.value = 'private_and_google';
};

const showSurveyRadioButton = computed(() => {
  return props.surveySchemas.length + props.surveys.length > 0;
});

const showEsatisOption = computed(() => {
  return props.esatisActivated && selectedCanal.value === 'email' && selectedClinicsHaveEsatis2Activated();
});

const selectedClinicsHaveEsatis2Activated = () => {
  const selectedIds = store.establishments;
  const selectedClinics = props.clinics.filter(clinic => selectedIds.includes(clinic.id));
  return selectedClinics.every(hasActivatedEsatis2);
};

const hasActivatedEsatis2 = clinic => {
  if (clinic.esatis2_activated_at === null) {
    return false; // or true, depending on your logic for null values
  }

  const activatedAt = new Date(clinic.esatis2_activated_at);
  return activatedAt !== 'Invalid Date' && activatedAt <= new Date();
};

const handleCloseAlert = () => {
  showError.value = false;
  initErrorMessage();
};

const handleSubmit = () => {
  storeSettings();
  emit('next-step');
};

const previewGoogleEmailTemplate = () => {
  emailType.value = 'google';
  showEmailTemplateModal.value = true;
};

const previewSurveyEmailTemplate = () => {
  emailType.value = 'survey';
  showEmailTemplateModal.value = true;
};

const previewEsatisEmailTemplate = () => {
  emailType.value = 'esatis';
  showEmailTemplateModal.value = true;
};

const emailPreviewModalTitle = computed(() => {
  let title = '';
  switch (emailType.value) {
    case 'google':
      title = t('email_preview.title.review_email');
      break;
    case 'survey':
      title = t('email_preview.title.survey_email');
      break;
    case 'esatis':
      title = t('email_preview.title.esatis_email');
      break;
    default:
      title = t('email_preview.title.review_email');
      break;
  }
  return title;
});

const storeSettings = () => {
  store.udpateSettings({
    sendingOption: selectedCanal.value,
    sendingMoment: selectedMoment.value,
    sendingDate: selectedDate.value,
    redirectionType: selectedRedirection.value,
    survey: selectedSurvey.value,
    sendEsatisEmailing: selectedEsatisEmailing.value,
  });
};

const capitalizedName = name => {
  return _.capitalize(name);
};

const navigateToPage = path => {
  window.location.href = path;
};
</script>
<style setup>
:root {
  --vs-border-radius: 8px;
  --vs-border-color: #d1d5db;
  --vs-selected-bg: #e1effe;
  --vs-selected-border-color: #e1effe;
  --vs-selected-color: #4691f7;
  --vs-controls-color: #4691f7;
}
</style>
