<template>
  <div class="flex h-full flex-col justify-between">
    <div id="flash" class="absolute z-50">
      <div class="fixed right-4 top-20 mt-2 grid grid-cols-1 gap-4">
        <SimpleToast v-show="showSuccess" index="1" model="success" :message="successMessage" @close="handleCloseToast"></SimpleToast>
      </div>
    </div>
    <div>
      <form class="max-w-lg text-left" @submit.prevent>
        <div
          v-if="showError"
          class="mb-5 flex w-full items-center justify-between border-l-2 border-danger bg-red-100 p-4 text-sm text-danger"
          @click="handleCloseAlert"
        >
          <span class="mr-2 text-red-dark">{{ errorMessage }}</span>
          <hero-icon name="CloseHeroIcon" classes="hover:cursor-pointer" size="small"></hero-icon>
        </div>

        <fieldset>
          <div class="ml-4 sm:ml-6">
            <div class="mb-8 flex flex-col items-start gap-4">
              <div class="text-sm text-textPrimary">
                {{ $t(`import_patients.configure_sollicitation.this_sollicitation_will_let_patients_for_${solicitationType}`) }}
              </div>
              <div class="text-sm font-semibold text-textPrimary">
                {{ $t('import_patients.configure_sollicitation.this_sollicitation_will_let_patients', 2) }}
              </div>
              <div class="ml-8 flex flex-col items-start gap-4">
                <div class="flex items-center">
                  <input
                    id="redirection-private-only"
                    v-model="v$.selectedRedirection.$model"
                    type="radio"
                    name="redirection"
                    value="private_only"
                    class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                  />
                  <label for="redirection-private-only" class="ms-2 flex items-center gap-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    <span>
                      {{ $t('import_patients.configure_sollicitation.give_a_review_on_mercidocteur_only') }}
                    </span>
                    <span class="flex h-full items-center text-gray-medium hover:cursor-pointer hover:text-primary">
                      <hero-icon name="EyeHeroIcon" size="default" @click="previewGoogleEmailTemplate"></hero-icon>
                    </span>
                  </label>
                </div>
                <div class="flex items-center">
                  <input
                    id="redirection-google-only"
                    v-model="v$.selectedRedirection.$model"
                    type="radio"
                    name="redirection"
                    value="google_only"
                    class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                  />
                  <label for="redirection-google-only" class="ms-2 flex items-center gap-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    <span>
                      {{ $t('import_patients.configure_sollicitation.give_a_review_on_google_only') }}
                    </span>
                    <span class="flex h-full items-center text-gray-medium hover:cursor-pointer hover:text-primary">
                      <hero-icon name="EyeHeroIcon" size="default" @click="previewGoogleEmailTemplate"></hero-icon>
                    </span>
                  </label>
                </div>
                <div class="flex items-center">
                  <input
                    id="redirection-google-and-private"
                    v-model="v$.selectedRedirection.$model"
                    type="radio"
                    name="redirection"
                    value="private_and_google"
                    class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                  />
                  <label
                    for="redirection-google-and-private"
                    class="ms-2 flex items-center gap-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    <span>
                      {{ $t('import_patients.configure_sollicitation.give_a_review_on_mercidocteur_and_google') }}
                    </span>
                    <span class="flex h-full items-center text-gray-medium hover:cursor-pointer hover:text-primary">
                      <hero-icon name="EyeHeroIcon" size="default" @click="previewGoogleEmailTemplate"></hero-icon>
                    </span>
                  </label>
                </div>
                <div v-if="showSurveyRadioButton" class="flex items-center">
                  <input
                    id="redirection-survey"
                    v-model="v$.selectedRedirection.$model"
                    type="radio"
                    name="redirection"
                    value="survey"
                    class="form-radio h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-blue-600 dark:focus:ring-blue-600"
                  />
                  <label for="redirection-survey" class="ms-2 flex items-center gap-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    <span>
                      {{ $t('import_patients.configure_sollicitation.respond_to_survey') }}
                    </span>
                    <span class="flex h-full items-center text-gray-medium hover:cursor-pointer hover:text-primary">
                      <hero-icon name="EyeHeroIcon" size="default" @click="previewSurveyEmailTemplate"></hero-icon>
                    </span>
                  </label>
                </div>
                <div v-if="showSelectSurveys">
                  <select
                    id="surveys"
                    v-model="selectedSurvey"
                    class="ml-6 block h-10 w-full rounded-lg border border-gray-300 bg-white px-2.5 text-xs font-medium text-gray-500 hover:drop-shadow-md"
                  >
                    <option v-for="survey in surveySchemas" :key="'schema' + survey.id" :value="{ id: survey.id, type: 'survey_schema' }">
                      {{ capitalizedName(survey.name) }}
                    </option>
                    <option v-for="survey in surveys" :key="survey.id" :value="{ id: survey.id, type: 'survey' }">
                      {{ capitalizedName(survey.description) }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <div class="mt-10 flex w-full justify-end">
          <button
            class="relative flex rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-gray-light disabled:text-textLight"
            :disabled="v$.$invalid"
            @click="handleSubmit"
          >
            <TurningLoader v-if="loading" size="sm" class="mr-2" />
            {{ $t('buttons.save') }}
          </button>
        </div>
      </form>
    </div>
    <Modal
      v-model="showEmailTemplateModal"
      :title="emailPreviewModalTitle"
      wrapper-class="modal-wrapper"
      modal-class="modal modal-lg max-h-[650px] overflow-auto"
    >
      <EmailPreviewModal :clinic-id="clinic.id" :email-type="emailType" @close="showEmailTemplateModal = false" />
    </Modal>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import EmailPreviewModal from '@components/shared/modals/EmailPreviewModal.vue';
import SimpleToast from '@components/shared/toasts/SimpleToast.vue';
import { updateExtensionSettings, updateQrCodeSettings, updateSftpSettings } from '@services/clinicService';

const { t } = useI18n();

const props = defineProps({
  solicitationType: {
    type: String,
    required: true,
  },
  clinic: {
    type: Object,
    required: true,
  },
  surveys: {
    type: Array,
    required: false,
    default: () => {
      return [];
    },
  },
  surveySchemas: {
    type: Array,
    required: false,
    default: () => {
      return [];
    },
  },
  patientSolicitation: {
    type: String,
    required: true,
  },
  patientSolicitationSurveyId: {
    type: Number,
    default: undefined,
  },
  patientSolicitationSurveySchemaId: {
    type: Number,
    default: undefined,
  },
});

const selectedRedirection = ref('google');
const selectedSurvey = ref(null);
const emailType = ref('google');
const showEmailTemplateModal = ref(false);
const loading = ref(false);
const successMessage = ref('');
const showSuccess = ref(false);
const errorMessage = ref('');
const showError = ref(false);

onMounted(() => {
  initErrorMessage();
  if (props.patientSolicitation) {
    selectedRedirection.value = props.patientSolicitation.includes('survey') ? 'survey' : props.patientSolicitation || 'private_and_google';
  }
  defineDefaultSelectedSurvey();
});

watch(showEmailTemplateModal, () => {
  if (showEmailTemplateModal.value) {
    document.body.classList.add('overflow-hidden');
  } else {
    document.body.classList.remove('overflow-hidden');
  }
});

const showSelectSurveys = computed(() => {
  return selectedRedirection.value === 'survey';
});

const rules = computed(() => {
  const localRules = {
    selectedRedirection: {
      required,
    },
  };
  if (selectedRedirection.value == 'survey') {
    localRules.selectedSurvey = {
      required,
    };
  }
  return localRules;
});

const v$ = useVuelidate(rules, { selectedRedirection, selectedSurvey });

const initErrorMessage = () => {
  errorMessage.value = t('users.standard_user_adding_message');
};

const defineDefaultSelectedSurvey = () => {
  if (props.patientSolicitation === 'survey' && props.patientSolicitationSurveyId && props.surveys.length > 0) {
    selectedSurvey.value = { id: props.patientSolicitationSurveyId, type: 'survey' };
  } else if (props.patientSolicitation === 'survey_schema' && props.patientSolicitationSurveySchemaId && props.surveySchemas.length > 0) {
    selectedSurvey.value = { id: props.patientSolicitationSurveySchemaId, type: 'survey_schema' };
  } else if (props.patientSolicitation === 'survey' && props.patientSolicitationSurveyId === undefined && props.surveys.length > 0) {
    selectedSurvey.value = { id: props.surveys[0].id, type: 'survey' };
  } else if (
    props.patientSolicitation === 'survey_schema' &&
    props.patientSolicitationSurveySchemaId === undefined &&
    props.surveySchemas.length > 0
  ) {
    selectedSurvey.value = { id: props.surveySchemas[0].id, type: 'survey_schema' };
  } else {
    selectedSurvey.value = props.surveySchemas[0]
      ? { id: props.surveySchemas[0].id, type: 'survey_schema' }
      : props.surveys[0]
        ? { id: props.surveys[0].id, type: 'survey' }
        : null;
  }
};

const previewGoogleEmailTemplate = () => {
  emailType.value = 'google';
  showEmailTemplateModal.value = true;
};

const previewSurveyEmailTemplate = () => {
  emailType.value = 'survey';
  showEmailTemplateModal.value = true;
};

const emailPreviewModalTitle = computed(() => {
  let title = '';
  switch (emailType.value) {
    case 'google':
      title = t('email_preview.title.review_email');
      break;
    case 'survey':
      title = t('email_preview.title.survey_email');
      break;
    case 'esatis':
      title = t('email_preview.title.esatis_email');
      break;
    default:
      title = t('email_preview.title.review_email');
      break;
  }
  return title;
});

const showSurveyRadioButton = computed(() => {
  return props.surveySchemas.length + props.surveys.length > 0;
});

const handleCloseAlert = () => {
  showError.value = false;
  initErrorMessage();
};

const displayErrorMessage = error => {
  showError.value = true;
  errorMessage.value = error;
};

const handleSubmit = async () => {
  try {
    loading.value = true;
    switch (props.solicitationType) {
      case 'extension':
        await updateExtensionSettings({
          clinicId: props.clinic.id,
          patientSolicitation: definePatientSolicitation(selectedRedirection.value, selectedSurvey.value?.type),
          patientSolicitationSurveyId:
            selectedRedirection.value === 'survey' && selectedSurvey.value?.type === 'survey' ? selectedSurvey.value.id : null,
          patientSolicitationSurveySchemaId:
            selectedRedirection.value === 'survey' && selectedSurvey.value?.type === 'survey_schema' ? selectedSurvey.value.id : null,
        });
        break;
      case 'sftp':
        await updateSftpSettings({
          clinicId: props.clinic.id,
          patientSolicitation: definePatientSolicitation(selectedRedirection.value, selectedSurvey.value?.type),
          patientSolicitationSurveyId:
            selectedRedirection.value === 'survey' && selectedSurvey.value?.type === 'survey' ? selectedSurvey.value.id : null,
          patientSolicitationSurveySchemaId:
            selectedRedirection.value === 'survey' && selectedSurvey.value?.type === 'survey_schema' ? selectedSurvey.value.id : null,
        });
        break;
      case 'qr_code':
        await updateQrCodeSettings({
          clinicId: props.clinic.id,
          patientSolicitation: definePatientSolicitation(selectedRedirection.value, selectedSurvey.value?.type),
          patientSolicitationSurveyId:
            selectedRedirection.value === 'survey' && selectedSurvey.value?.type === 'survey' ? selectedSurvey.value.id : null,
          patientSolicitationSurveySchemaId:
            selectedRedirection.value === 'survey' && selectedSurvey.value?.type === 'survey_schema' ? selectedSurvey.value.id : null,
        });
        break;
    }
    loading.value = false;
    showError.value = false;
    showSuccess.value = true;
    successMessage.value = t('users.update_notifications.success');
  } catch (error) {
    displayErrorMessage(error.message);
    loading.value = false;
  }
};

const definePatientSolicitation = (redirectionType, survey_type) => {
  return redirectionType === 'survey' ? survey_type : redirectionType;
};

const handleCloseToast = () => {
  showSuccess.value = false;
  successMessage.value = '';
};

const capitalizedName = name => {
  return _.capitalize(name);
};
</script>
