<template>
  <div
    :id="toast_id"
    class="top-24 flex w-full max-w-xs items-center justify-between rounded-xl drop-shadow-xl"
    :class="additionalClasses"
    role="alert"
  >
    <div class="flex h-full items-center rounded-l-xl p-5" :class="usedIconBgColor">
      <span class=""><hero-icon :name="usedIcon" classes="h-6 w-6" :class="usedIconColor"></hero-icon></span>
    </div>
    <div class="flex h-full grow items-center rounded-r-xl p-5" :class="usedBgColor">
      <div class="mr-2 text-sm" :class="usedTextColor">{{ message }}</div>
      <button
        type="button"
        class="inline-flex h-8 w-8 items-center justify-center rounded-lg border-none bg-transparent p-1.5 focus:ring-2 focus:ring-gray-300"
        aria-label="Close"
        :data-dismiss-target="toast_id_ref"
        @click="$emit('close')"
      >
        <span class="sr-only">Close</span>
        <hero-icon name="CloseHeroIcon" :classes="usedIconCloseColor"></hero-icon>
      </button>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue';

const props = defineProps({
  index: {
    type: String,
    required: true,
  },
  model: {
    type: String,
    required: false,
    default: 'info', // 'danger, info, warning, success'...
  },
  message: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: false,
    default: null, // 'InformationOutlineIonIcon'
  },
  iconColor: {
    type: String,
    required: false,
    default: null, // 'text-primary'
  },
  iconCloseColor: {
    type: String,
    required: false,
    default: null, // 'text-gray-400 hover:text-primary',
  },
  bgColor: {
    type: String,
    required: false,
    default: null, // 'bg-white'
  },
  iconBgColor: {
    type: String,
    required: false,
    default: null, // 'bg-white'
  },
  textColor: {
    type: String,
    required: false,
    default: null, // 'text-gray-500 font-normal'
  },
  type: {
    type: String,
    required: false,
    default: null, // 'autoclose', 'dismissible'
  },
});

const emit = defineEmits(['close']);

const usedType = ref('');
const usedTextColor = ref('');
const usedIconBgColor = ref('');
const usedBgColor = ref('');
const usedIconCloseColor = ref('');
const usedIconColor = ref('');
const usedIcon = ref('');
const additionalClasses = ref('animate-in');

onMounted(() => {
  switch (props.model) {
    case 'danger':
      usedType.value = props.type || 'dismissible';
      usedIcon.value = props.icon || 'AlertOutlinetIonIcon';
      usedIconColor.value = props.iconColor || 'text-white';
      usedIconCloseColor.value = props.iconCloseColor || 'text-red-700 hover:text-gray-700';
      usedIconBgColor.value = props.iconBgColor || 'bg-red-500';
      usedBgColor.value = props.bgColor || 'bg-red-100';
      usedTextColor.value = props.textColor || 'text-red-700';
      break;
    case 'info':
      usedType.value = props.type || 'autoclose';
      usedIcon.value = props.icon || 'InformationOutlineIonIcon';
      usedIconColor.value = props.iconColor || 'text-white';
      usedIconCloseColor.value = props.iconCloseColor || 'text-primary hover:text-gray-700';
      usedIconBgColor.value = props.iconBgColor || 'bg-primary';
      usedBgColor.value = props.bgColor || 'bg-blue-100';
      usedTextColor.value = props.textColor || 'text-blue-dark';
      break;
    case 'warning':
      usedType.value = props.type || 'dismissible';
      usedIcon.value = props.icon || 'WarningOutlineHeroIcon';
      usedIconColor.value = props.iconColor || 'text-yellow-600';
      usedIconCloseColor.value = props.iconCloseColor || 'text-yellow-600 hover:text-gray-700';
      usedIconBgColor.value = props.iconBgColor || 'bg-yellow';
      usedBgColor.value = props.bgColor || 'bg-yellow-100';
      usedTextColor.value = props.textColor || 'text-yellow-600';
      break;
    case 'success':
      usedType.value = props.type || 'autoclose';
      usedIcon.value = props.icon || 'CheckCircleOutlineHeroIcon';
      usedIconColor.value = props.iconColor || 'text-white';
      usedIconCloseColor.value = props.iconCloseColor || 'text-green-dark hover:text-gray-700';
      usedIconBgColor.value = props.iconBgColor || 'bg-green';
      usedBgColor.value = props.bgColor || 'bg-green-100';
      usedTextColor.value = props.textColor || 'text-green-dark';
      break;
    default:
  }
  if (usedType.value === 'autoclose') {
    additionalClasses.value = 'animate-dismissible top-24';
    setTimeout(() => {
      emit('close');
    }, 6500);
  }
});

const toast_id = computed(() => {
  return `toast_${props.index}`;
});

const toast_id_ref = computed(() => {
  return `#toast_${props.index}`;
});
</script>
<style scoped>
@keyframes appear-then-fade {
  0%,
  100% {
    opacity: 0;
    transform: translateX(0px);
  }
  5%,
  60% {
    opacity: 1;
    transform: translateX(-20px);
  }
}

@keyframes slide-fade-in {
  0% {
    opacity: 0;
    transform: translateX(0px);
  }
  100% {
    opacity: 1;
    transform: translateX(-20px);
  }
}

.animate-dismissible {
  animation: appear-then-fade 6s both;
}

.animate-in {
  animation: slide-fade-in 1s both;
}
</style>
