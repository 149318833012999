<template>
  <div>
    <div id="flash" class="absolute z-50">
      <div class="fixed right-4 top-20 mt-2 grid grid-cols-1 gap-4">
        <SimpleToast v-show="showSuccess" index="0" model="success" :message="successMessage" @close="handleCloseToast"></SimpleToast>
      </div>
    </div>
    <div v-if="showError" class="mb-4 overflow-hidden rounded-lg bg-red-50 p-4 text-sm text-red-800 dark:bg-gray-800 dark:text-red-400" role="alert">
      {{ t('users.update_notifications.errors', { error: errorMessage }) }}
    </div>
    <form class="max-w-[500px]" @submit.prevent="submitTemplate">
      <h4 class="mb-6 text-textSecondary">{{ $t('notifications_rules.title', { email: user.email }) }}</h4>
      <div
        v-for="(notificationsRule, name) in currentNotificationRules"
        :key="name"
        class="mt-1 inline-flex w-full flex-wrap items-center justify-between space-y-2"
      >
        <label class="pointer-events-none flex w-96 flex-col">
          <span class="pointer-events-none text-base font-medium text-textPrimary">{{ $t(`notifications_rules.${name}`) }}</span>
        </label>
        <input v-if="!loading" v-model="currentNotificationRules[name]" type="checkbox" class="peer sr-only" />
        <div
          :class="[
            'peer relative h-6 w-11 cursor-pointer justify-end rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[\'\'] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 sm:w-20 md:w-11',
          ]"
          @click="toggleCheckbox(name)"
        ></div>
      </div>
      <div class="mt-10 flex w-full justify-end">
        <button
          class="relative flex rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-gray-light disabled:text-textLight"
          :disabled="disableSubmitButton"
        >
          <TurningLoader v-if="loading" size="sm" class="mr-2" />
          {{ $t('users.save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { updateNotificationsRules } from '@services/userService';
import { useI18n } from 'vue-i18n';
import SimpleToast from '../toasts/SimpleToast.vue';

const { t } = useI18n();

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  notificationRules: {
    type: Object,
    required: true,
  },
});

const currentNotificationRules = ref(null);
const savedNotificationRules = ref(null);
const loading = ref(false);
const successMessage = ref('');
const showSuccess = ref(false);
const errorMessage = ref('');
const showError = ref(false);

onMounted(() => {
  currentNotificationRules.value = { ...props.notificationRules };
  savedNotificationRules.value = { ...props.notificationRules };
});

const sameNotificationRules = computed(() => {
  return JSON.stringify(savedNotificationRules.value) === JSON.stringify(currentNotificationRules.value);
});

const disableSubmitButton = computed(() => {
  return loading.value || sameNotificationRules.value;
});

const toggleCheckbox = name => {
  currentNotificationRules.value[name] = !currentNotificationRules.value[name];
};

const handleCloseToast = () => {
  showSuccess.value = false;
  successMessage.value = '';
};

const displaySuccessMessage = () => {
  showError.value = false;
  showSuccess.value = true;
  successMessage.value = t('users.update_notifications.success');
};

const displayErrrorMessage = error => {
  showError.value = true;
  errorMessage.value = error;
};

const submitTemplate = async () => {
  try {
    loading.value = true;
    const res = await updateNotificationsRules({ userId: props.user.id, ...currentNotificationRules.value });
    if (res) {
      savedNotificationRules.value = {
        badReview: res.data.notifications.bad_review,
        newReview: res.data.notifications.new_review,
      };
      displaySuccessMessage();
    }
    loading.value = false;
  } catch (error) {
    displayErrrorMessage(error.message);
    loading.value = false;
  }
};
</script>
